// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import type { DossierType, Prestation } from '../../types/SimutTypes';
import { findTypePrestation } from '../../utils';
import PlanningModal from '../../components/PlanningModal';

type Props = {
  simut: {
    dossier: DossierType,
    depannage: any,
    remboursement: any,
    otherPrestations: any,
    poursuites: any,
    servicePlus: any,
    prestations: Prestation[],
  },
}

type State = {
  showPlanning: boolean[],
}

export default class AssistanceSante extends React.Component<Props, State> {
  state = {
    showPlanning: [],
  };

  componentDidMount(): * {
    const prestations = this.props.simut.prestations;
    let showPlanning = [];
    prestations.map(prestation => {showPlanning.push(false);});
    this.setState({
      showPlanning,
    })
  }

  togglePlanning = (index : number) => {
    let showPlanning = this.state.showPlanning;
    showPlanning[index] = !showPlanning[index];

    this.setState({
      showPlanning,
    });
  };

  render() {
    const { simut } = this.props;
    if (!simut) {
      return false;
    }
    return (
      <div className="home">
        {simut.prestations && simut.prestations.map((prestation, index) => {
          const type_prestation = findTypePrestation(prestation.libelle);
          const detailIntervention = prestation.intervention.detailIntervention;
          const detailPlanning = prestation.intervention.detailPlanning;
          const prestataire = prestation.prestataire;
          const wordings = prestation.wording;
          return (
              <div>
                <div className="prestation-bloc">
                  {
                    ((detailPlanning.plannings &&
                    detailPlanning.plannings.length > 0) ||
                    (detailPlanning.trajet && detailPlanning.trajet.length > 0)) &&
                    <PlanningModal
                      togglePlanning={() => this.togglePlanning(index)}
                      isOpen={this.state.showPlanning[index]}
                      detailPlanning={detailPlanning}
                      type_prestation={type_prestation}
                      index={index}
                    />
                  }
                  <div className="prestation-title">
                    {type_prestation.icon !== '' ? <i className={type_prestation.icon} /> : <i className="picto-title logo--OTHER"/>}
                    {prestation.libelle}
                  </div>

                  <div className="intervention-prestataire">
                    <div className="intervention">
                      <div className="intervention-title">
                        Intervention
                      </div>
                      <div className="intervention-content">
                      {
                        detailIntervention &&
                        detailIntervention.map(detail => {
                        return (
                            <div className="value">
                              {detail.cle} : {detail.valeur}
                            </div>
                        )
                      })}
                        {detailPlanning &&
                        ((detailPlanning.trajet && detailPlanning.trajet.length > 0) ||
                            (detailPlanning.plannings && detailPlanning.plannings.length > 0)) &&
                        <button className="digi-button" onClick={() => this.togglePlanning(index)}>
                          <span>Planning</span>
                        </button>
                        }
                      </div>
                    </div>

                    <div className="intervention">
                      <div className="intervention-title">
                        {prestataire.libelle_rubrique}
                      </div>
                      <div className="intervention-content">
                        {
                          prestataire.nom &&
                          <div className="value">
                            {prestataire.nom}
                          </div>
                        }
                        {
                          prestataire.adresse &&
                          prestataire.adresse.rue &&
                          <div className="value">
                            {prestataire.adresse.rue}
                          </div>
                        }
                        {
                          prestataire.adresse &&
                          prestataire.adresse.cp &&
                          prestataire.adresse.ville &&
                          <div className="value">
                            {prestataire.adresse.cp + ' ' + prestataire.adresse.ville}
                          </div>
                        }
                        {
                          prestation.prestataire.horraire &&
                          <div className="value">
                            {prestataire.horraire}
                          </div>
                        }
                        {
                          prestation.prestataire.contact &&
                          <div className="value">
                            {prestataire.contact}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="advise">
                    <div className="title">{I18n.t('advise.title')}</div>
                    {wordings && wordings.map(wording => {
                      return (
                        <div className="message">
                          {wording.titre && wording.titre !== '' ?
                            <div>
                              <span>{wording.titre}</span>
                              <ul>
                                {wording.texte && wording.texte.map(text => {
                                  return <li>{text}</li>;
                                })}
                              </ul>
                            </div> :
                            <div>
                              {wording.texte && wording.texte.map(text => {
                                return <p>{text}</p>;
                              })}
                            </div>
                          }
                        </div>
                      );
                    })}
                  </div>
                </div>
                {index !== (simut.prestations.length - 1) &&
                  <div className="prestation-separator" />
                }
              </div>
          );
        })}
      </div>
    );
  }
}
