// @flow
import React from 'react';
import moment from 'moment';
import { InView } from 'react-intersection-observer';

import { PrestationRapatriement } from '../../types/SimutTypes';
import Accompagnement from './Accompagnement';
import type { Rapatriement } from '../../types/SimutTypes';
import Prestation from './Prestation';

type Props = {
    rapatriement: Rapatriement,
    id: string,
    setDateSelected: (string) => void,
}

export default class JourRapatriement extends React.Component<Props> {
    changeIdSelected = (inView) => {
      if (inView) {
        this.props.setDateSelected(this.props.rapatriement.dateRapatriement);
      }
    }

    render() {
      const { rapatriement, id } = this.props;

      return (
        <div className="rapatriement-slider-element" id={id.replaceAll('/', '-')}>
          <div className="date-rapatriement">
            {/* permet de savoir sur quel id on est après avoir scrollé */}
            <InView
              as="span"
              onChange={this.changeIdSelected}
            />
            {moment(rapatriement.dateRapatriement, 'DD-MM-YYYY').locale('fr').format('dddd Do MMMM YYYY')}
          </div>

          <div className="tuile-rapatriement">
            {rapatriement.accompagnementMedical &&
            <Accompagnement accompagnementMedical={rapatriement.accompagnementMedical} />
                    }
            {React.Children.toArray(rapatriement.prestations.map((prestation: PrestationRapatriement) => <Prestation prestation={prestation} />))}
          </div>
        </div>
      );
    }
}
