// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import type { LocationType } from '../types/SimutTypes';

type Props = {
  objet: LocationType,
  servicePlus?: ?boolean,
}

export default function CarLocation({ objet, servicePlus }: Props) {
  const dureeNombre = (servicePlus || objet.duree >= 3) ? objet.duree : objet.duree * 24;
  const dureeDescriptif = (servicePlus || objet.duree >= 3) ? ' jours' : ' heures';

  return (
    <div className="bloc-type">
      <div className="bloc-title">
        {I18n.t(`poursuite.${objet.type}`)}
      </div>

      {objet.nom &&
      <div className="bloc-field">
        <div className="label">
          {I18n.t('poursuite.nom')}
        </div>
        <div className="values">
          {objet.nom}
        </div>
      </div>
      }

      {objet.telephone &&
      <div className="bloc-field">
        <div className="label">
          {I18n.t('poursuite.telephone')}
        </div>
        <div className="values">
          <span><a href={`tel:${objet.telephone}`}>{objet.telephone}</a></span>
        </div>
      </div>
      }

      <div className="bloc-field">
        <div className="label">
          {I18n.t('poursuite.depart')}
        </div>
        <div className="values">
          {objet.libelle_depart && `${objet.libelle_depart}`}
          {objet.adresse_depart && ` ${objet.adresse_depart} `}
          {
            objet.telephone_depart &&
            <span><a href={`tel:${objet.telephone_depart}`}>{objet.telephone_depart}</a></span>
          }
        </div>
      </div>

      {objet.heure_depart &&
      <div className="bloc-field">
        <div className="label">
        </div>
        <div className="values">
          {objet.heure_depart}
        </div>
      </div>
      }

      <div className="bloc-field">
        <div className="label">
          {I18n.t('poursuite.arrivee')}
        </div>
        <div className="values">
          {objet.libelle_arrivee && `${objet.libelle_arrivee}`}
          {objet.adresse_arrivee && ` ${objet.adresse_arrivee} `}
          {
            objet.telephone_arrivee &&
            <span><a href={`tel:${objet.telephone_arrivee}`}>{objet.telephone_arrivee}</a></span>
          }
        </div>
      </div>

      {objet.duree &&
      <div className="bloc-field">
        <div className="label">
          {I18n.t('poursuite.duree')}
        </div>
        <div className="values">
          {dureeNombre}
          {dureeDescriptif}
        </div>
      </div>
      }

      {objet.nbr_passager &&
      <div className="bloc-field">
        <div className="label">
          {I18n.t('poursuite.nbr_passager')}
        </div>
        <div className="values">
          {objet.nbr_passager}
        </div>
      </div>
      }

    </div>
  );
}
