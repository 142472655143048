// @flow
import React, { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import { Redirect, Route, Switch } from 'react-router-dom';
import { withMabla } from './mabla/Mabla';
import { ASSISTANCE, DOCUMENTS, HOME, MENTIONS, REVIEW, themes, TYPE_DOSSIER } from './const';
import Header from './components/Header';
import simutApi from './network/api/simutApi';
import type { DossierType, GeoType, InformationRapatriement } from './types/SimutTypes';
import { queryParams } from './utils';
import MentionsLegales from './pages/example/MentionsLegales';
import googleApi from './network/api/googleApi';
import { withGA } from './gogleAnalytics/GoogleAnalytics';
import Review from './pages/Review';
import DossierAuto from './pages/dossiers/DossierAuto';
import DossierSante from './pages/dossiers/DossierSante';
import AssistanceSante from './pages/assistances/AssistanceSante';
import AssistanceAuto from './pages/assistances/AssistanceAuto';
import DossierMedical from './pages/dossiers/DossierMedical';
import AssistanceMedical from './pages/assistances/AssistanceMedical';
import DocumentsMedical from './pages/documents/DocumentsMedical';
import onetrustApi from './network/api/onetrustApi';

const getTheme = themKey => Promise.resolve(themes[themKey]);

const provideTheme = async themeKey => await getTheme(themeKey);

const getOnetrust = () => onetrustApi.fetchOnetrustToken().then(response => response.text());
const getToken = () => googleApi.fetchGAToken().then(response => response.text());
const provideToken = async () => await getToken();

const setupAnalitycs = (token) => {
  if (token !== '') {
    const scriptOt = document.createElement('script');
    scriptOt.setAttribute('type', 'text/javascript');

    const scriptOtFunction = document.createTextNode('function OptanonWrapper() { OneTrust.OnConsentChanged(function() {window.location.reload()}) }');
    scriptOt.appendChild(scriptOtFunction);

    document.head.appendChild(scriptOt);

    const script = document.createElement('script');
    script.setAttribute('type', 'text/plain');
    script.classList.add('optanon-category-2');

    const scriptText = document.createTextNode(`${"(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){\n" +
        '(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),\n' +
        'm=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)\n' +
        "})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');\n" +
        '\n' +
        "ga('create', '"}${token}', 'auto');\n` +
        'ga(\'send\', \'pageview\');');

    script.appendChild(scriptText);
    document.body.appendChild(script);
  }
};

const setupOnetrust = async (token: string) => {
  if (token !== '') {
    const scriptOt = document.createElement('script');
    scriptOt.setAttribute('src', 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js');
    scriptOt.setAttribute('type', 'text/javascript');
    scriptOt.setAttribute('charset', 'UTF-8');
    scriptOt.setAttribute('data-domain-script', token);

    document.head.appendChild(scriptOt);

    const GAtoken = await provideToken();
    setupAnalitycs(GAtoken);
  }
};

type Props = {};

type State = {
  error_token: boolean,
  simut: ?{
    dossier: DossierType,
    depannage: any,
    remboursement: any,
    poursuites: any,
    servicePlus: any,
    prestations: [],
    informationRapatriement: InformationRapatriement,
  },
  geo: GeoType,
}

class App extends React.Component <Props, State> {
  state = {
    simut: null,
    error_token: false,
    geo: {
      latitude: 0,
      longitude: 0,
      error_message: '',
    },
    otToken: '',
  };

  fetchSimut = () => {
    getOnetrust()
      .then(response => response)
      .then(token => this.setState({
        otToken: token,
      }, () => {
        setupOnetrust(token);
      }));
    if (queryParams.token) {
      simutApi
        .fetch(queryParams.token)
        .then(rep => rep.json())
        .then((json) => {
          this.setState(
            { simut: json },
            () => {
              if (this.state.simut.depannage) {
                simutApi
                  .fetchGeo(queryParams.token)
                  .then(rep => rep.json())
                  .then((reponse) => {
                    this.setState({ geo: reponse });
                  });
              }
            },
          );
        });
    } else {
      this.setState({ error_token: true });
    }
  };

  componentDidMount() {
    this.fetchSimut();
  }

  renderHome = () => {
    if (!this.state.simut || !this.state.simut.dossier) {
      return null;
    }

    const typeDossier = this.state.simut.dossier.type_dossier;
    if (typeDossier === TYPE_DOSSIER.AUTO) {
      return (<DossierAuto
        dossier={this.state.simut && this.state.simut.dossier}
        remboursements={this.state.simut && this.state.simut.remboursements}
      />);
    } else if (typeDossier === TYPE_DOSSIER.SANTE) {
      return (
        <DossierSante
          dossier={this.state.simut && this.state.simut.dossier}
          remboursements={this.state.simut && this.state.simut.remboursements}
        />);
    } else if (typeDossier === TYPE_DOSSIER.MEDICAL) {
      return (
        <DossierMedical
          dossier={this.state.simut && this.state.simut.dossier}
          remboursements={this.state.simut && this.state.simut.remboursements}
        />);
    }
    return (
      <Fragment>
        <Header />
        <div id="error-content">
          {I18n.t('error')}
        </div>
      </Fragment>);
  };

  renderAssistance = () => {
    if (!this.state.simut || !this.state.simut.dossier) {
      return null;
    }

    const typeDossier = this.state.simut.dossier.type_dossier;
    if (typeDossier === TYPE_DOSSIER.AUTO) {
      return <AssistanceAuto simut={this.state.simut} />;
    } else if (typeDossier === TYPE_DOSSIER.SANTE) {
      return <AssistanceSante simut={this.state.simut} />;
    } else if (typeDossier === TYPE_DOSSIER.MEDICAL) {
      return <AssistanceMedical simut={this.state.simut} />;
    }
    return (
      <Fragment>
        <Header />
        <div id="error-content">
          {I18n.t('error')}
        </div>
      </Fragment>
    );
  };

  renderDocuments = () => {
    if (!this.state.simut || !this.state.simut.dossier) {
      return null;
    }

    const typeDossier = this.state.simut.dossier.type_dossier;
    if (typeDossier === TYPE_DOSSIER.MEDICAL) {
      return <DocumentsMedical token={queryParams.token} simut={this.state.simut} />;
    }
    return null;
  }

  renderFooter = () => {
    const token = this.state.otToken;
    return (<div className="footer">
      {this.state.simut &&
      <span onClick={() => this.props.history.push(`${MENTIONS}?token=${queryParams.token}`)}>{I18n.t('mentions')} </span>}
      {this.state.simut && token !== '' && <span>&nbsp;|&nbsp;</span>}
      {token !== '' && <div className="onetrust-btn-container">
        <a className="optanon-toggle-display">{I18n.t('cookie.handle')}</a>
      </div>}
    </div>);
  }

  render() {
    const isClosed = this.state.simut && this.state.simut.dossier.statusCode === '200';
    const closedMessage = this.state.simut && this.state.simut.dossier.statusCode === '200' && this.state.simut.dossier.error_message;
    const isErrorDossier = this.state.simut && this.state.simut.dossier.statusCode !== '200' && this.state.simut.dossier.error_message;
    const isErrorPoursuite = this.state.simut &&
      this.state.simut.poursuites &&
      this.state.simut.poursuites.error_message;
    const isErrorServicePlus = this.state.simut &&
      this.state.simut.servicePlus &&
      this.state.simut.servicePlus.error_message;
    const isError = isErrorDossier || isErrorPoursuite || isErrorServicePlus;

    if (isError || this.state.error_token) {
      return (
        <Fragment>
          <Header />
          <div id="error-content">
            {I18n.t('error')}
          </div>
          {this.renderFooter()}
        </Fragment>
      );
    }
    if (isClosed) {
      return (
        <div id="app">
          <Header type_dossier="" isClosed />
          <main id="main-content">
            <div id="error-content">{
              closedMessage
            }
            </div>
          </main>
          {this.renderFooter()}
        </div>
      );
    }
    return (
      <div id="app">
        {this.state.simut &&
        <Fragment>
          <Header type_dossier={this.state.simut.dossier.type_dossier} />
          <main id="main-content">
            <Switch>
              <Route exact path={HOME} render={this.renderHome} />
              <Route exact path={DOCUMENTS} render={this.renderDocuments} />
              <Route exact path={ASSISTANCE} render={this.renderAssistance} />
              <Route exact path={MENTIONS} component={MentionsLegales} />
              <Route
                exact
                path={REVIEW}
                render={() => <Review dossier={this.state.simut.dossier} />}
              />
              <Route path="*" render={() => <Redirect to={HOME} />} />
            </Switch>
          </main>
          {this.renderFooter()}
        </Fragment>
        }
      </div>
    );
  }
}

export default withGA({
  WrappedComponent: withMabla({ WrappedComponent: App, provideTheme: provideTheme('Mutuaide') }),
  provideToken: provideToken(),
});
