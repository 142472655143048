// @flow
import React from 'react';

const getIconType = (libelle: string): string => {
  switch (libelle.toUpperCase().trim()) {
    case ('AMBULANCE'):
      return 'fa-ambulance';
    case ('AVION SANITAIRE'):
    case ('AVION AIR FRANCE'):
    case ('AVION DE LIGNE ASSIS'):
    case ('AVION DE LIGNE'):
    case ('AVION DE LIGNE EXTRA-SEAT (SIÈGE SUPPLÉMENTAIRE)'):
    case ('AVION DE LIGNE CIVIÈRE (VOYAGE ALLONGÉ)'):
      return 'fa-plane-departure';
    case ('DÉPANNAGE'):
    case ('REMORQUAGE'):
    case ('RELIVRAISON DE VÉHICULE'):
      return 'fa-car-crash';
    case ('REMORQUAGE AUTOROUTE'):
      return 'fa-road';
    case ('CARBURANT'):
      return 'fa-gas-pump';
    case ('TÉLÉCOMMUNICATIONS'):
      return 'fa-phone';
    case ('VÉHICULE DE LOCATION'):
    case ('VÉHICULE DE PRÊT PANNE'):
    case ('VÉHICULE DE PRÊT ACC/RÉPAR'):
    case ('VÉHICULE DE PRÊT VOL'):
    case ('VÉHICULE DE PRÊT EPAVE'):
    case ('VÉHICULE DE PRÊT'):
      return 'fa-car-side';
    case ('TAXI'):
    case ('VSL'):
    case ('CHAUFFEUR'):
    case ('CHAUFFEUR DE REMPLACEMENT'):
      return 'fa-taxi';
    case ('HÔTEL'):
      return 'fa-hotel';
    case ('TRAIN'):
      return 'fa-train';
    default:
      return 'fa-user-md';
  }
};

type Props = {
  libelle: string,
}

export default function IconType({ libelle }: Props) {
  return (
    <div className={`icon fa ${getIconType(libelle)}`} />
  );
}