// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import type { DossierType, RemboursementDossier } from '../../types/SimutTypes';
import Remboursement from '../../components/remboursement/Remboursement';

type Props = {
  remboursements: RemboursementDossier,
  dossier: DossierType,
  history?: Object,
}

type State = {
}

class DossierSante extends React.PureComponent<Props, State> {
  state = {
  };

  render() {
    const { remboursements } = this.props;
    const { dossier } = this.props;
    if (!dossier) {
      return false;
    }

    return (
      <div>
        <div className="file">
          <div className="icon fa fa-user" />
          <div className="info-line">
            <p>{dossier.nom_cdo}</p>
            <p>{I18n.t('dossier.reference', { reference: dossier.numero_dossier })}</p>
            <p>{I18n.t('dossier.beneficiary', { beneficiary: dossier.beneficiaire })}</p>
            <p>{I18n.t('dossier.location', { address: dossier.adresse })}</p>
          </div>
        </div>
        <Remboursement remboursements={remboursements} dossier={dossier} />
        <div className="file file--assistance">
          <div className="info-line">
            <span className="info">
              {dossier.libelle_assistance}
            </span>
          </div>
          <div className="separator" />
          <div className="info-line">
            <span className="info info--italic">{dossier.msg_etat_dossier}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(DossierSante);
