// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { ASSISTANCE, DOCUMENTS, HOME, REVIEW, TYPE_DOSSIER } from '../const';
import { queryParams } from '../utils';

type Props = {
  history?: Object,
  location?: Object,
  type_dossier?: string,
  type_webapp?: string,
}
type State = {
  showWelcome: boolean,
  menuOpen: boolean,
  selectedItem: boolean[],
}

class Header extends React.Component<Props, State> {
  state = {
    showWelcome: true,
    menuOpen: true,
    selectedItem: [],
  };

  toggleWelcome = (value) => {
    this.setState({ showWelcome: value });
  };

  toggleMenuIcon = () => {
    this.setState(prevState => ({ menuOpen: !prevState.menuOpen }));
    this.toggleWelcome(false);
  };
  setSelectedItem = (value) => {
    this.setState({ selectedItem: value });
  };

  componentDidMount() {
    const app = document.getElementById('main-content');
    if (app) {
      app.addEventListener('scroll', () => {
        const value = app.scrollTop;
        if (this.menu && value >= (this.menu.offsetHeight)) {
          this.toggleWelcome(false);
        }
      }, false);
    }
    if (window.location.pathname.substring(1) === 'home') {
      this.setSelectedItem([true, false, false, false]);
    } else if (window.location.pathname.substring(1) === 'assistance') {
      this.setSelectedItem([false, true, false, false]);
    } else if (window.location.pathname.substring(1) === 'mentions') {
      this.setSelectedItem([false, false, false, false]);
    } else if (window.location.pathname.substring(1) === 'documents') {
      this.setSelectedItem([false, false, true, false]);
    }  else if (window.location.pathname.substring(1) === 'review') {
      this.setSelectedItem([false, false, false, true]);
    }
  }

  componentWillUpdate(prevProps, prevState) {
    if (prevProps.location !== this.props.location && window.location.pathname.substring(1) === 'mentions' && prevState.selectedItem !== [false, false, false]) {
      this.setSelectedItem([false, false, false, false]);
    }
  }

  handleClick = (link) => {
    let bool = [];
    const app = document.getElementById('main-content');
    if (app && link === 0) {
      bool = [true, false, false, false];
      this.props.history.push(`${HOME}?token=${queryParams.token}`);
      app.scrollTop = 0;
    } else if (link === 1) {
      bool = [false, true, false, false];
      this.props.history.push(`${ASSISTANCE}?token=${queryParams.token}`);
    } else if (link === 2) {
      bool = [false, false, true, false];
      this.props.history.push(`${DOCUMENTS}?token=${queryParams.token}`);
    } else if (link === 3) {
      bool = [false, false, false, true];
      this.props.history.push(`${REVIEW}?token=${queryParams.token}`);
    }
    this.setState({ selectedItem: bool });
  };

  menu: ? HTMLElement;

  render() {
    return (
      <header>
        <div className="title" ref={(el) => {
          this.menu = el;
        }}>{I18n.t('header.title')}</div>
        <div className="container-menu">
          <div className={`welcome ${this.state.showWelcome ? '' : 'welcome--hidden'}`}>
            {I18n.t('header.welcome')}
          </div>
          <div className={`menu ${this.state.menuOpen ? '' : 'menu--closed'} ${this.props.type_dossier === TYPE_DOSSIER.MEDICAL ? 'menu--medical' : ''}`}>
            <div className="menu-item" onClick={() => this.handleClick(0)}>
              <i className="header-logo logo--file" />
            </div>
            <div className="menu-item" onClick={() => this.handleClick(1)}>
              <i className="header-logo logo--assistance" />
            </div>
            {
              this.props.type_dossier === TYPE_DOSSIER.MEDICAL &&
              <div className="menu-item" onClick={() => this.handleClick(2)}>
                <i className="header-logo logo--documents" />
              </div>
            }
            <div className="menu-item" onClick={() => this.handleClick(3)}>
              <i className="header-logo logo--review" />
            </div>
          </div>
          <div className={`menu ${this.props.type_dossier === TYPE_DOSSIER.MEDICAL ? 'menu--medical' : ''}`}>
            <div
              className={`menu-title ${this.state.selectedItem[0] ? 'menu-title--selected' : ''}`}
              onClick={() => this.handleClick(0)}
            >
              {I18n.t('header.dossier')}
            </div>
            <div
              className={`menu-title ${this.state.selectedItem[1] ? 'menu-title--selected' : ''}`}
              onClick={() => this.handleClick(1)}
            >
              <div>{I18n.t('header.assistance')}</div>
            </div>
            {this.props.type_dossier === TYPE_DOSSIER.MEDICAL && (
              <div
                className={`menu-title ${this.state.selectedItem[2] ? 'menu-title--selected' : ''}`}
                onClick={() => this.handleClick(2)}
              >
                <div>{I18n.t('header.documents')}</div>
              </div>
            )}
            <div
              className={`menu-title ${this.state.selectedItem[3] ? 'menu-title--selected' : ''}`}
              onClick={() => this.handleClick(3)}
            >
              <div>{I18n.t('header.avis')}</div>
            </div>
          </div>
          <div
            className="languette"
            onClick={() => this.toggleMenuIcon()}
          >
            <i className={`fa ${this.state.menuOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
