// @flow
import React from 'react';
import { I18n } from 'react-redux-i18n';

type Props = {
    start: string,
    end: string,
}

export default function DisplayStartEndDate({ start, end }: Props) {
  if (start && end) {
    return (
      <small>
        {I18n.t('remboursement.date_start_to_end', { date_start: start, date_end: end })}
      </small>
    );
  } else if (start) {
    return (
      <small>
        {I18n.t('servicePlus.date', { date: start })}
      </small>
    );
  }
  return null;
}