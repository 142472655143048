// @flow

import React from 'react';
import type {Planning} from "../types/SimutTypes";
import {I18n} from "react-redux-i18n";

type Props = {
  planning: Planning,
}

const VALIDATE = 'validée';
const PLANNED = 'plannifiée';
const CANCELED = 'annulée';

export default function PlanningIntervention({ planning }: Props) {
  return (
      <div className="planning-content">
        <div className="planning-date">{planning.date}</div>
        <div className="planning-details">
          <div className="planning-details-title">{I18n.t('planning.planned')}</div>
          <div>
            {planning.qt_plannifiee !== '' ? I18n.t('planning.planned_to', {heure_deb: planning.heure_deb, heure_fin: planning.heure_fin}) : ''}
          </div>
          <div>
            <span>{planning.qt_plannifiee !== '' ? planning.qt_plannifiee : '-'}</span>
          </div>
        </div>
        <div className="planning-details">
          <div className="planning-details-title">{I18n.t('planning.realized')}</div>
          <div>
            {planning.qt_realisee !== '' ? I18n.t('planning.planned_to', {heure_deb: planning.heure_deb, heure_fin: planning.heure_fin}) : ''}
          </div>
          <div>
            <span>{planning.qt_realisee !== '' ? planning.qt_realisee : '-'}</span>
          </div>
        </div>
        <div>
          {planning.statut === VALIDATE && <i className="fas fa-check-circle" />}
          {planning.statut === CANCELED && <i className="fas fa-times-circle" />}
          {planning.statut === PLANNED && <i className="far fa-clock" />}
        </div>
      </div>
  );
}
