// @flow

import React from 'react';
import type {Trajet} from "../types/SimutTypes";
import {I18n} from "react-redux-i18n";
import BlocRoundTrip from "./BlocRoundTrip";

type Props = {
  trajet: Trajet,
}

export default function PlanningTrajet({ trajet }: Props) {
  return (
      <div className="trajet-content">
        <div className="trajet-date">{trajet.trajet_aller.date}</div>
        <div className="trajet-details">
          {trajet.trajet_aller &&
          <div>
            <div className="trajet-title">{I18n.t('planning.trip_go')}</div>
            <BlocRoundTrip
                heure_dep={trajet.trajet_aller.heure}
                adresse_dep={trajet.trajet_aller.adresse_dep}
                adresse_ret={trajet.trajet_aller.adresse_arr}/>
          </div>
          }

          {trajet.trajet_retour &&
          <div className="trajet-round-trip">
            <div className="trajet-title">{I18n.t('planning.trip_back')}</div>
            <BlocRoundTrip
                heure_dep={trajet.trajet_retour.heure}
                adresse_dep={trajet.trajet_retour.adresse_dep}
                adresse_ret={trajet.trajet_retour.adresse_arr}/>
          </div>
          }
        </div>
      </div>
  );
}
