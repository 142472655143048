// @flow
/* eslint-disable global-require */
import React from 'react';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import { I18n } from 'react-redux-i18n';
import type { DossierType, GeoType } from '../types/SimutTypes';

type Props = {
  google: any,
  depanneur: GeoType,
  dossier: DossierType,
}

type State = {
  bounds: ?Object,
}

class GoogleMap extends React.Component<Props, State> {
  map: ?Object;
  state = {
    bounds: {},
  };
  manageBounds = () => {
    const bounds = new this.props.google.maps.LatLngBounds();
    const myLoc = new this.props.google.maps.LatLng(
      this.props.dossier.gps.latitude,
      this.props.dossier.gps.longitude,
    );
    const depanneurLoc = new this.props.google.maps.LatLng(
      this.props.depanneur.latitude,
      this.props.depanneur.longitude,
    );
    bounds.extend(myLoc);
    bounds.extend(depanneurLoc);

    this.setState(
      { bounds },
      () => this.map ? this.map.map.fitBounds(this.state.bounds) : {},
    );
  };

  componentDidMount() {
    if (this.props.google !== undefined) {
      this.manageBounds();
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.google !== undefined && this.props.depanneur !== nextProps.depanneur) {
      this.manageBounds();
    }
  }

  render() {
    return (
      <Map
        mapTypeControl={false}
        streetViewControl={false}
        ref={(map) => { this.map = map || null; }}
        google={this.props.google}
        zoom={13}
        initialCenter={{
          lat: (this.props.dossier.gps.latitude + this.props.depanneur.latitude) / 2,
          lng: (this.props.dossier.gps.longitude + this.props.depanneur.longitude) / 2,
        }}
        style={{ width: '100%', height: '100%' }}
      >

        <Marker
          name={I18n.t('googleMap.depanneur')}
          position={{
            lat: parseFloat(this.props.depanneur.latitude),
            lng: parseFloat(this.props.depanneur.longitude),
          }}
          icon={{
            url: require('../img/map/truck-icon.svg'),
            scaledSize: new this.props.google.maps.Size(30, 30),
          }}
        />

        <Marker
          name={I18n.t('googleMap.client')}
          position={{
            lat: parseFloat(this.props.dossier.gps.latitude),
            lng: parseFloat(this.props.dossier.gps.longitude),
          }}
          icon={{
            url: require('../img/map/standing-up-man.svg'),
            scaledSize: new this.props.google.maps.Size(30, 30),
          }}
        />
      </Map>
    );
  }
}

export default GoogleApiWrapper((props) => ({
  apiKey: props.apiKey,
}))(GoogleMap);
