// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import type { HotelType } from '../types/SimutTypes';

type Props = {
  objet: HotelType,
}
export default function Hotel({ objet }: Props) {

  const petitDejeuner = objet.petit_dejeuner ? I18n.t('poursuite.petit_dejeuner') : '';
  const debut = moment(objet.date_depart).format('DD/MM/YYYY');

  return (
    <div className="bloc-type">
      <div className="bloc-title">{I18n.t('poursuite.hotel')}</div>
      <div className="bloc-field">
        <div className="values-solo">
          {objet.nom && `${objet.nom}`}
          {objet.adresse && `
          ${objet.adresse}`}
          {
            objet.telephone &&
            <span><a href={`tel:${objet.telephone}`}>{` ${objet.telephone}`}</a></span>
          }
        </div>
      </div>
      <div className="bloc-field">
        {objet.nbr_chambres &&
        <div className="label">
          {I18n.t('poursuite.nbr_chambres')}
        </div>}
        {objet.nbr_chambres &&
        <div className="values">
          {objet.nbr_chambres}
        </div>}
      </div>
      <div className="bloc-field">
        <div className="label">
          {I18n.t('poursuite.arrivee')}
        </div>
        <div className="values">
          {debut}
        </div>
      </div>
      {objet.date_fin &&
      <div className="bloc-field">
        <div className="label">
          {I18n.t('poursuite.depart')}
        </div>
        <div className="values">
          {objet.date_fin}
        </div>
      </div>}
      { petitDejeuner &&
      <div className="bloc-field">
        <div className="values-solo">
          {petitDejeuner}
        </div>
      </div>}
    </div>
  );
}
