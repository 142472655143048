
import { RestClient } from '../network';

const googleApi = {
  fetchKey: () =>
    new RestClient('/google/key', 'GET').execute(),
  fetchGAToken: () =>
    new RestClient('/google/ga', 'GET').execute(),
};

export default googleApi;
