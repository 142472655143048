// @flow

import React from 'react';
import Star from './Star';
import { I18n } from 'react-redux-i18n';

type Props = {
  stars: Object[],
  input: {
    value: number,
    onChange: Function,
  }
}

const StarList = ({ stars, input}: Props) => (
    <div className="stars-container">
        <div className="label">{I18n.t('review.unsatisfied')}</div>
        <div className="stars">
          {
            stars.map((star, index) =>
              (<Star
                key={star.grade}
                color={input.value > index ? star.color : null}
                onClick={() => input.onChange(star.grade)}
              />))
          }
        </div>
        <div className="label">{I18n.t('review.satisfied')}</div>
    </div>
);

export default StarList;
