// @flow

import React from 'react';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';

type Props = {
  type: string,
  heure: string,
  date: string,
  children: any,
}

export default function BlocDepannage({
  type, children, heure, date,
}: Props) {
  const newDate = new Date();
  const givenDate = moment(date).format('DD/MM/YYYY');
  const isDemain = moment(givenDate).diff(newDate, 'days') === 1 ? I18n.t('depannage.location') : moment(date).format('DD/MM/YYYY').slice(0, 5);
  const isToday = moment(givenDate).diff(newDate, 'days') === 0 ? I18n.t('depannage.location') : isDemain;
  return (
    <div className="bloc-depannage">
      <div className="flux">
        <div className="date">
          {isToday}
        </div>
        <div className="date">
          {heure}
        </div>
      </div>
      <div className="picto-type">
        <i className={`picto logo--${type}`} />
      </div>
      <div className="main-poursuite">
        {
          children
        }
      </div>
    </div>
  );
}
