// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import type {
  DossierType,
  InformationRapatriement,
  Prestation,
} from '../../types/SimutTypes';
import ProchainContact from '../../components/ProchainContact';
import ListeRapatriementsContainer from '../../components/rapatriement/ListeRapatriementsContainer';

type Props = {
  simut: {
    dossier: DossierType,
    depannage: any,
    remboursement: any,
    otherPrestations: any,
    poursuites: any,
    servicePlus: any,
    prestations: Prestation[],
    informationRapatriement: InformationRapatriement,
  },
}

export default function AssistanceMedical({ simut }: Props) {
  if (!simut && !simut.informationRapatriement) {
    return false;
  }
  if (!simut.informationRapatriement.libelleContact &&
    !simut.informationRapatriement.rapatriements.length > 0
  ) {
    return (
      <div id="error-content">
        {I18n.t('error')}
      </div>);
  }

  return (
    <div className="home">
      {simut.informationRapatriement.libelleContact &&
        <ProchainContact
          className="prestation-bloc"
          libelle={simut.informationRapatriement.libelleContact}
        />
      }
      {simut.informationRapatriement.rapatriements.length > 0 &&
        <ListeRapatriementsContainer
          rapatriements={simut.informationRapatriement.rapatriements}
        />
      }
    </div>
  );
}
