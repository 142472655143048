// @flow

import React from 'react';
import ReactGA from 'react-ga';
import simutApi from "../network/api/simutApi";
import { TYPE_DOSSIER, TYPE_WEBAPP } from "../const";

type Props = {
  WrappedComponent: React.Component,
  provideTheme: Function,
};

export function withGA({WrappedComponent, provideToken}: Props) {
  const token = new URLSearchParams(window.location.search).get('token');
  let webapp = '';
  let cdo = '';
  let motifAppel = '';

  let gaIsInitialize = false;

  const getWebapp = async () => {
    if (token) {
      await simutApi
        .fetch(token)
        .then(rep => rep.json())
        .then((json) => {
          cdo = json.dossier.nom_cdo || '';
          motifAppel = json.dossier.motif_appel || '';
          switch (json.dossier.type_dossier) {
            case TYPE_DOSSIER.AUTO: {
              webapp = TYPE_WEBAPP.AUTO;
              break;
            }
            case TYPE_DOSSIER.SANTE: {
              webapp = TYPE_WEBAPP.SANTE;
              break;
            }
            case TYPE_DOSSIER.MEDICAL: {
              webapp = TYPE_WEBAPP.MEDICAL;
              break;
            }
            default: webapp = '';
          }
        });
    }
  }

  const trackPage = (page) => {
    if (gaIsInitialize) {
      ReactGA.set({
        screenName: page,
      });
      ReactGA.pageview('/' + webapp + page);
    }
  };

  const initToken = async () => {
    const GAtoken = await provideToken;
    if (typeof GAtoken === 'string') {
      await getWebapp();
      ReactGA.pageview('/' + webapp + window.location.pathname);
      ReactGA.event({
        category: webapp + '/CDO',
        action: cdo
      });
      ReactGA.event({
        category: webapp + '/motifAppel',
        action: motifAppel
      });
      return GAtoken
    }
    return null;
  };

  (async () => {
    if (!gaIsInitialize) {
      const token = await initToken();
      gaIsInitialize = !!token;
    }
  })();

  return class extends React.Component<Props> {
    componentDidUpdate(prevProps: Props) {
      getWebapp().then(() => {
        const previousUrl = prevProps.location.pathname;
        const newUrl = this.props.location.pathname;
        if (previousUrl !== newUrl) {
          trackPage(newUrl);
        }
      });
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  };
}
