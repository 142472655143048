const fr = {
  example: {
    modalContent: 'Je suis une modale',
  },
  actions: {
    BACK: 'Retour',
    CANCEL: 'Annuler',
    SAVE: 'Enregistrer',
    DELETE: 'Supprimer',
    NEW: 'Nouveau',
    CONFIRM: 'Confirmer',
    SEARCH: 'Rechercher',
  },
  message: {
    SUCCESS: 'Les modifications ont bien été prises en compte',
    ERROR: 'Les modifications ont échoués',
    YES: 'Oui',
    NO: 'Non',
  },
  wsError: {
    INTERNAL_ERROR: 'Erreur de connexion',
    AUTHENTIFICATION_EXPIRED: 'Session d\'administration expirée',
    FIELD_REQUIRED: 'Le champ \'%{field0}\' est requis',
    COLOR_INVALID: 'Le champ \'%{field0}\' doit être une couleur au format hexadecimale : #ff00ff',
    EMAIL_INVALID: 'L\'adresse e-mail \'%{field0}\' n\'est pas valide',
    USERNAME_ALREADY_EXISTS: 'Cet identifiant est déjà utilisé sur un autre compte, veuillez en choisir un autre',
    EMAIL_ALREADY_EXISTS: 'Cette adresse e-mail est déjà utilisée sur un autre compte, veuillez en choisir une autre',
    PASSWORDS_DIFFERENT: 'Les mots de passe \'%{field0}\' et \'%{field1}\' doivent être identiques',
    WRONG_LOGIN_OR_PASSWORD: 'Nom d\'utilisateur ou mot de passe incorrect',
    TOO_MANY_WRONG_ATTEMPS: 'Suite à des erreurs dans la saisie de vos identifiants, votre compte est verrouillé pendant %{field0} minutes, veuillez-vous reconnecter ultérieurement',
    ROLE_LABEL_EXISTS: 'Il existe déjà un rôle avec ce libellé',
    PASSWORD_TOO_SHORT: 'Le mot de passe est trop court, il doit faire au moins %{field0} caractères',
    MESSAGE: '%{field0}',
  },
  date: {
    long: 'DD MMMM YYYY HH:mm',
    DATE_START: 'Date de début',
    DATE_END: 'Date de fin',
    DATE_FORMAT: 'DD/MM/YYYY',
  },
  cookie: {
    handle: 'Gérer mes cookies',
  },
  header: {
    title: 'Mon assistance personnalisée',
    dossier: 'Dossier et Remboursements',
    documents: 'Vos documents',
    assistance: 'Votre assistance',
    avis: 'Avis Clients',
    welcome: `Bienvenue !
                    Suite à votre appel auprès de nos services, votre dossier est en cours de traitement.
                    Retrouvez ici le récapitulatif de votre dossier ainsi que des informations utiles.
                        `,
  },

  dossier: {
    reference: 'Référence : %{reference}',
    number: 'Numéro de dossier : %{number}',
    incident: '%{type} le',
    beneficiary: 'Bénéficiaire : %{beneficiary}',
    location: 'Lieu : %{address}',
    address: ':',
    contact: 'Votre contact assistance 24/24',
    locateMe: 'Localisez-moi'
  },
  document: {
    dossierNumber: 'Dossier n° %{number}',
    title: 'Liste des documents à nous transmettre',
    subtitle: 'Vous trouverez ci-dessous la liste des documents nécessaires à l’analyse et au traitement de votre demande. ' +
        'Les formats acceptés sont : BMP, DOC, DOCX, GIF, JPEG, JPG, PDF, PNG.',
    add: 'Ajouter',
    edit: 'Modifier',
    transmit: 'Transmettre',
    error: 'Désolé, cet envoi dépasse 22 Mo ou ne correspond pas à un format accepté. Veuillez vérifier vos fichiers et recommencer.'
  },
  remboursement: {
    status_date: '%{status} le %{date}',
    date_start_to_end: ' Du %{date_start} au %{date_end}',
    justificatifs_en_attente: 'Justificatifs en attente %{attente} / %{total}',
    avance_frais: 'REMBOURSEMENT - AVANCE DE FRAIS',
    tips: 'BON À SAVOIR',
    mailSent: 'Votre demande a bien été prise en compte.',
    mailSentError: 'Une erreur est survenue lors de l\'envoi de votre demande. Veuillez réessayer ultérieurement.',
  },

  advise: {
    title: 'Bon à savoir',
    remboursement: `Pour plus de rapidité, pensez à mettre votre n° de dossier dans l'objet du mail. 
    
      Le remboursement sera effectué sous 4 semaines environ après réception des justificatifs.`,
    location: `Il vous sera demandé un permis de conduire, une pièce d'identité au nom du conducteur ainsi qu'une carte bancaire avec les chiffres en relief dont l'empreinte servira de caution.
      
      Le carburant et les péages ne sont pas pris en charge.
      
      Assurance : vérifiez avec votre assureur que votre garantie couvre la location de voiture.`,
    train_borne: 'Votre code de réservation vous sera demandé en borne afin de retirer votre billet.',
    train_ebillet: 'Votre billet a été envoyé, merci de prendre connaissance de vos emails. Pensez à vérifier vos spam.',
    hotel: 'Les autres frais annexes ne sont pas pris en charge.',
  },

  poursuite: {
    hotel: 'Hôtel',
    location: 'Location de voiture',
    taxi: 'Taxi',
    train: 'Train',
    depart: 'Départ',
    arrivee: 'Arrivée',
    eTicket: 'e-billet',
    nbr_chambres: 'Nombre de chambre(s) retenue(s)',
    reservation: 'N° réservation',
    retour: 'Retour du véhicule : ',
    retour_date: 'Le %{date} à %{heure}',
    retour_heure: 'Heure : ',
    petit_dejeuner: 'Petit-déjeuner inclus',
    taxes: 'Taxes de séjour incluses',
    petit_dejeuner_taxes: 'Petit-déjeuner et taxes de séjour inclus',
    duree: 'Durée',
    a: ' à ',
    nbr_passager: 'Passagers',
    nom : 'Nom',
    telephone: 'Téléphone',
  },

  depannage: {
    title: 'Dépannage',
    intervention: 'Intervention',
    rdv1: 'Date de rendez-vous : %{date}',
    rdv2: 'Heure de rendez-vous : %{heure}',
    pec: 'Date d\'intervention : %{date}',
    immmediat: 'Délai estimé : %{delai}\u00a0%{temps}',
    depanneur: 'Dépanneur',
    livraison: 'La voiture sera livrée : ',
    minutes: 'minute(s)',
    day: 'Aujourd\'hui',
    tomorrow: 'Demain',
    noInfos: 'Pas d\'information disponible',
  },

  planning: {
    planned: 'Planifiées',
    planned_to: '%{heure_deb} à %{heure_fin}',
	realized: 'Réalisées',
    coming_intervention: 'Interventions à venir',
    past_intervention: 'Interventions passées',
    coming_trip: 'Déplacements à venir',
    past_trip: 'Déplacements passés',
    trip_go: 'Trajet aller',
    trip_back: 'Trajet retour',
  },

  assistance: {
    title: 'Poursuite de mon trajet',
    medicale: {
      title: 'Votre assistance voyage',
      remboursement: 'Votre demande de remboursement',
      association: 'Association',
      date: 'Date : %{date}',
      rapatriement: {
        title: 'Votre rapatriement',
        medicalAssistance: 'Votre accompagnement médical',
        prestationStartLabel: 'Départ : ',
        prestationHourStart: ' à %{heure}',
        address: ' %{adresse1} %{adresse2} %{adresse3} %{cp} %{ville}',
        prestationEndLabel: 'Arrivée : ',
        prestationHourEnd: ' à %{heure}',
        beneficiaires: 'Bénéficiaires',
        prestationTrain: 'Train n° : ',
        prestationPlane: 'Vol n° : ',
        prestationHotel: 'Nombre de nuit(s) : ',
        prestationBooking: 'Réservation n° %{number}',
        prestationEquipment: 'Équipement :',
      },
    },
  },
  servicePlus: {
    title: 'Votre service +',
    date: 'Le %{date}',
  },
  review: {
    title: 'Pour nous aider à améliorer notre service, donnez-nous votre avis sur votre assistance.',
    satisfied: 'Très satisfait',
    unsatisfied: 'Très insatisfait',
    questions: {
      contact: 'Suite à votre appel, êtes-vous satisfait de votre contact avec le service assistance ?',
      quality: 'Si vous avez bénéficié de nos services, êtes-vous satisfait de l\'intervention de nos prestataires ?',
      comment: 'Des remarques ? Recommandations ? Laissez-nous votre avis.',
    },
    placeholder: 'Saisisssez un commentaire',
    submit: 'Envoyer mes réponses',
    response: 'Vos réponses ont été enregistrées, merci de votre participation ! ',
    rgpd: {
      firstBlock: 'Mutuaide Assistance collecte et traite vos données personnelles afin d’assurer la gestion de nos ' +
          'prestations d’assistance, leur suivi en direct sur l’application et de mesurer votre taux de satisfaction. Vous pouvez consulter ',
      link: 'notre politique de protection des données',
      lastBlock: ' pour en savoir plus sur vos droits et le traitement de vos données.',
      consent: 'Afin de répondre à vos besoins, Mutuaide assistance peut être amenée à collecter les données de santé' +
          ' que vous renseignez dans le champ commentaire ci-dessous. Votre consentement est nécessaire pour collecter ' +
          'ce type de données. Il peut être retiré, à tout moment en exerçant une demande auprès de ',
      consentMail: 'DRPO@MUTUAIDE.fr',
      giveConsent: 'Je donne mon consentement'
    }
  },

  geolocation: {
    success: 'Nous avons bien récupéré votre position géographique.',
    error: 'Nous n\'avons pas pu récupérer votre position, veuillez réessayer plus tard.',
  },
  googleMap: {
    depanneur: 'Depanneur',
    client: 'Votre Position',
  },
  error: 'Aucune donnée disponible, Veuillez réessayer plus tard',
  mentions: 'Mentions Légales',
  mentionslegales: {
    title: {
      editeur: 'Editeur du site',
      directeur: 'Directeur de la publication',
      hebergeur: 'Hébergeur du site',
      droit: 'Droit applicable',
      propriete: 'Droit de la propriété intellectuelle',
      acces: 'Accès, fonctionnalités et utilisation générale de l’application web mobile',
      reclamations: 'Réclamations',
      donnees: 'Traitement des données personnelles',
    },
    text: {
      editeur: `Mutuaide Assistance, 126 rue de la Piazza, CS 20010 – 93196 Noisy le Grand CEDEX. SA au capital de 12 558 240€ – Entreprise régie par le Code des Assurances.
      Soumise au contrôle de l’Autorité de Contrôle Prudentiel de Résolution – 4 Place de Budapest CS 92459, 75436 Paris Cedex 09 – 383 974 086 RCS Bobigny – TVA FR 31 383 974 086`,
      directeur: 'Jean-Baptiste DESPREZ',
      hebergeur: 'Mutuaide Assistance',
      droit: `La présente « web application mobile » est soumise à la loi française.

En cas de litige, les tribunaux compétents seront ceux du siège social de Mutuaide Assistance.
`,
      propriete: `La présente application web mobile relève de la réglementation applicable au droit d’auteur et à la propriété intellectuelle. Tous les droits de reproduction et de représentation du contenu de cette application web mobile sont réservés.
      
Les marques et noms de produits cités ont été déposés et protégés à ce titre.

Toute représentation ou reproduction, totale ou partielle de ces éléments, par quelque procédé que ce soit, sans l’autorisation expresse de Mutuaide Assistance est interdite et constituerait une  contrefaçon au sens des articles L.335-2 et suivants du Code de la Propriété Intellectuelle. Il en est de même des bases de données figurant sur le site.
`,
      acces: `DIGILEAN est une application web mobile ayant pour objet de fournir aux utilisateurs des informations sur les prestations d’assistance mises en place dans le cadre  de leurs sinistres automobiles.
      
L’application web mobile est accessible à partir d’un lien URL envoyé par SMS à l’utilisateur à l’ouverture de son dossier d’assistance. A chaque actualisation des prestations mises en place l’utilisateur reçoit un nouveau lien URL.

L’application web mobile est accessible 24H sur 24, 7 jours sur 7, à l’exception des cas de force majeure, des difficultés techniques et/ou informatiques et/ou de télécommunication et/ou de période de maintenance.

Mutuaide met tout en œuvre pour offrir aux utilisateurs des informations, outils et services disponibles et vérifiés. Il s’engage à tout mettre en œuvre pour sécuriser conformément à l’état de l’art l’accès, la consultation et l’utilisation des informations transmises. 

L’utilisateur déclare accepter les risques et les limites de l’Internet, notamment de ses performances techniques et des temps de réponse pour consulter, interroger ou transférer les informations. Il reconnaît en outre qu’il est seul responsable de l’usage qu’il fait des informations.

Les informations contenues sur l’application web mobile DIGILEAN ne peuvent en aucun cas être considérées comme des conseils formels engageant la responsabilité de Mutuaide et/ou de leurs auteurs. Les services et les données sont uniquement fournis à titre d'information. Ni Mutuaide, ni aucun de ses partenaires fournisseurs d'informations ne sauraient être tenus pour responsables des erreurs, omissions, altérations ou délais de mise à jour des informations et de leurs éventuelles conséquences dans le cadre de leur utilisation. En aucun cas, ils ne sauraient être tenu pour responsables des dommages directs et indirects résultant de l'accès ou de l'usage de La web application mobile.
`,
      reclamations: `Nous sommes très attentifs à vous accompagner au quotidien et à vous apporter un service de qualité, n’hésitez pas à nous faire part de votre insatisfaction, si vous souhaitez formuler une réclamation, vous pouvez nous contacter par mail : qualite.assistance@mutuaide.fr

ou par courrier postal :
Mutuaide Assistance – Service Qualité Clients
126 rue de la Piazza, CS 20010
93196 Noisy le Grand CEDEX

Mutuaide Assistance s’engage à accuser réception de votre réclamation dans un délai maximum de 10 jours à compter de sa réception et à la traiter dans les deux mois au plus.

En dernier recours, vous pourrez vous mettre en relation par courrier avec le Médiateur, sans préjudice de votre droit de saisir éventuellement la justice.

Vous pouvez saisir le Médiateur :
soit par courrier à l’adresse suivante : 
La Médiation de l’Assurance 
TSA 50110 
75441 Paris Cedex 09, 

Soit par internet sur:
https://www.mediation-assurance.org/
`,
      donnees: `Les entités Mutuaide veillent en permanence au respect de la règlementation relative à la protection des données à caractère personnel (ci-après « données personnelles ») et le met au cœur de son éthique.
      
La présente politique d’information a pour objectif de vous informer de manière détaillée des conditions de traitement de vos données personnelles, ainsi que les droits et options dont vous disposez pour contrôler vos informations et protéger votre vie privée. Elle couvre les traitements liés à l’utilisation de l’application web mobile DIGILEAN.
      
Vous êtes actuellement connecté à l’application web mobile DIGILEAN accessible à partir de l’adresse URL qui vous a été adressée par SMS à l’ouverture de votre dossier d’assistance. Au cours de votre navigation, des données à caractère personnel vous concernant sont traitées afin de vous permettre d’obtenir des informations sur les prestations d’assistance mises en place dans le cadre de vos sinistres.

Ces données ont été recueillies dans le cadre de votre dossier d’assistance automobile et sont nécessaires à l’exécution du contrat d’assistance que vous avez souscrit avec Mutuaide.

Les données traitées dans la cadre cette application web mobile sont conservées pour la durée nécessaire à l’exécution de la prestation d’assistance et sont ensuite archivées conformément aux durées prévues par les dispositions relatives à la prescription.

Mutuaide traite l'ensemble des données à caractère personnel dont il a connaissance en conformité avec les réglementations en vigueur relatives au traitement de ces données et à la protection de la vie privée, notamment les dispositions de la loi informatique et libertés n°78-17 du 6 janvier 1978 modifiée et du Règlement (UE) générale sur la protection des données.

Mutuaide prend toutes les précautions utiles afin de préserver la confidentialité, l’intégrité et la sécurité des données à caractère personnel et notamment d’empêcher qu’elles ne soient déformées, endommagées ou communiquées à des personnes non autorisées, qu'il s'agisse de personnes privées ou publiques, physiques ou morales. 

Vous disposez en justifiant de votre identité d’un droit d’accès, de rectification, de suppression et d’opposition aux données traitées.  Vous disposez également du droit de demander la limitation de l’utilisation de ses données lorsqu’elles ne sont plus nécessaires, ou de récupérer dans un format structuré les données que vous avez fournies.

Vous disposez d’un droit de définir des directives relatives au sort de vos données personnelles après votre mort. Ces directives, générales ou particulières, concernent la conservation, l’effacement et la communication de vos données après votre décès.

Ces droits peuvent être exercés auprès du Délégué Représentant à la Protection des Données de l’Assureur :

par mail : à l’adresse DRPO@MUTUAIDE.fr 
ou	
par courrier : en écrivant à l’adresse suivante : 
Délégué représentant à la protection des données – MUTUAIDE ASSISTANCE – 126 rue de la Piazza, CS 20010 – 93196 Noisy le Grand CEDEX.

En cas de difficulté persistante malgré l’intervention du Délégué à la Protection des Données Personnelles, vous pouvez adresser une plainte à la Commission Nationale Informatique et Libertés (CNIL) sur le site internet www.cnil.fr ou en écrivant un courrier à l’adresse : CNIL – 3 Place de Fontenoy, TSA 80715 – 75334 PARIS CEDEX 07.
`,
    },
  },
};

export default fr;
