// @flow

export type DossierType = {
  type_dossier: string,
  numero_dossier: string,
  type: string,
  number: number,
  address: string,
  date: string,
  telephone: string,
  gps: GpsType,
  error_message: string,
  statusCode: string,
  nom_cdo: string,
  beneficiaire: string,
  adresse: string,
  libelle_assistance: string,
  msg_etat_dossier: string,
  beneficiaires: BeneficiaireType[],
  statut_dossier: string,
  motif_appel: string,
  email: string,
};

export type GpsType = {
  latitude: number,
  longitude: number,
};

export type BeneficiaireType = {
  civilite: string,
  nom: string,
  prenom: string,
};

export type HotelType = {
  type: string,
  nom: string,
  telephone: string,
  date_depart: string,
  date_fin: string,
  adresse: string,
  tel: string,
  nbr_chambres: number,
  petit_dejeuner: boolean,
  taxes: boolean,
};

export type LocationType = {
  adresse?: string,
  type: string,
  duree: number,
  libelle_depart: string,
  adresse_depart: string,
  telephone_depart: string,
  date_depart: string,
  heure_depart: string,
  libelle_arrivee: string,
  adresse_arrivee: string,
  telephone_arrivee: string,
  date_arrivee: string,
  heure_arrivee: string,
};

export type TaxiType = {
  type: string,
  nom: string,
  telephone: string,
  date_depart: string,
  heure_depart: string,
  adresse_depart: string,
  adresse_arrivee: string,
};

export type TrainType = {
  type: string,
  nom_depart: string,
  date_depart: string,
  heure_depart: string,
  nom_arrivee: string,
  date_arrivee: string,
  heure_arrivee: string,
  code_reservation: string,
  e_billet: string,
  retrait_borne: boolean,
};

export type DepannageType = {
  libelle: string,
  depanneur: DepanneurType,
  depot: DepotType,
  delai: string,
  date_intervention: string,
  rdv: RdvType,
  error_message: string,
};

export type DepanneurType = {
  nom: string,
  telephone: string,
};

export type DepotType = {
  nom: string,
  adresse: string,
  telephone: string,
};

export type RdvType = {
  date: string,
  heure: string,
};

export type GeoType = {
  latitude: number,
  longitude: number,
  error_message: string,
};

// Pour le dossier Santé
export type Detail = {
  cle: string,
  valeur: string,
};

export type Wording = {
  titre: string,
  texte: string[],
};

export type Adresse = {
  rue: string,
  cp: string,
  ville: string,
  pays: string,
};

export type Trajet = {
  date: string,
  heure: string,
  adresse_dep: Adresse,
  adresse_arr: Adresse,
};

export type AllerRetour = {
  trajet_aller: Trajet,
  trajet_retour: Trajet,
};

export type Planning = {
  statut: string,
  date: string,
  heure_deb: string,
  heure_fin: string,
  qt_plannifiee: string,
  qt_realisee: string,
};

export type DetailPlanning = {
  info_contact: Detail,
  is_transport: boolean,
  plannings: Planning[],
  trajet: AllerRetour[],
};

export type Intervention = {
  detailIntervention: Detail[],
  detailPlanning: DetailPlanning,
};

export type Prestataire = {
  libelle_rubrique: string,
  nom: string,
  adresse: Adresse,
  horraire: string,
  contact: string,
};

export type Prestation = {
  libelle: string,
  intervention: Intervention,
  prestataire: Prestataire,
  wording: Wording[],
};

export type TypePrestation = {
  libelle: string,
  planning: string,
  icon: string,
};

export type Document = {
  nom_doc: string,
  libelle_doc: string,
  code_doc: string,
  statut: string,
  date_maj: Date,
  detail_doc: string,
};

export type InformationRapatriement = {
  libelleContact: string,
  rapatriements: Rapatriement[],
};

export type Rapatriement = {
  accompagnementMedical: AccompagnementMedical,
  dateRapatriement: string,
  prestations: PrestationRapatriement[],
};

export type AccompagnementMedical = {
  libelle: string,
  beneficiaire: BeneficiaireRapatriement,
};

export type BeneficiaireRapatriement = {
  civilite: string,
  classeVoyage: string,
  nom: string,
  numReservation: string,
  prenom: string,
  qualite: string,
};

export type PrestationRapatriement = {
  adresse: AdresseRapatriement,
  beneficiaires: BeneficiaireRapatriement[],
  dateDebut: string,
  dateFin: string,
  equipements: string[],
  libellePrestation: string,
  lieuArrivee: LieuRapatriement,
  lieuDepart: LieuRapatriement,
  nombreNuit: string,
  numTrain: string,
  numVol: string,
};

export type LieuRapatriement = {
  heure: string,
  adresse: AdresseRapatriement,
};

export type AdresseRapatriement = {
  cp: string,
  libelle: string,
  ligne1: string,
  ligne2: string,
  ligne3: string,
  pays: string,
  ville: string,
};

// Remboursement

export type WordingRemboursement = {
  texte: string[],
};

export type Justificatif = {
  libelle: string,
  justificatifPrestationId: number,
  statut: string,
  dateStatut: string,
  detail: string,
};

export type PrestationRemboursement = {
  libellePrestation: string,
  dateDebutPrestation: string,
  dateFinPrestation: string,
  odmId: number,
  justificatifs: Justificatif[],
};

export type RemboursementDossier = {
  email: string,
  justificatifsCommun: Justificatif[],
  remboursementPrestations: PrestationRemboursement[],
  wording: WordingRemboursement,
};

export const JustificatifStatus = {
  RECIVED: 'Reçu',
  TREATED: 'Traité',
  PENDING: 'En Attente',
  FORWARDED: 'Transmis',
};

export type FileData = {
  files: File[],
  code_doc: string,
};

export type DataFile = {
  data: FileData,
  justificatif: Justificatif,
};


export type PoursuiteGlobal = {
  poursuites: Array<Date>,
  error_message: string,
};

export type Simut = {
  depannage: DepannageType,
  dossier: DossierType,
  InformationRapatriement: InformationRapatriement,
  poursuites: PoursuiteGlobal,
  prestations: Prestation[],
  remboursements: RemboursementDossier,
  servicePlus: PoursuiteGlobal,
};

export type JustificatifUpload = {
  token: string,
  odmId: number,
  justificatif: Justificatif,
};

export type MailInfoJustificatif = {
  subject: string,
  attachementData: File[],
  justificatifUpload: JustificatifUpload,
};

export type DocumentToUpdate = {
  token: string,
  document: Document,
};

export type MailInfoDossier = {
  subject: string,
  attachementData: File[],
  documentToUpdate: DocumentToUpdate,
};