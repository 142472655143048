// @flow
import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import { Justificatif, DossierType, JustificatifStatus, FileData } from '../../types/SimutTypes';
import useOutsideCloser from '../../hooks/useOutsideCloser';
import FileInput from './FileInput';


type Props = {
  justificatif: Justificatif,
  checkIsFileExist: () => void,
  setListFilesToSend: (files: File[]) => void,
  listFilesToSend: File[],
  dossier: DossierType,
  index: number,
}

function DispayStatus({ justificatif }: Props) {
  switch (justificatif.statut) {
    case JustificatifStatus.RECIVED:
      return (
        <p className="recived">
          {justificatif.dateStatut ? I18n.t('remboursement.status_date', { status: justificatif.statut, date: moment(justificatif.dateStatut).format('DD/MM/YYYY') }) : justificatif.dateStatut}
        </p>
      );
    case JustificatifStatus.TREATED:
      return (
        <p className="success">
          {justificatif.dateStatut ? I18n.t('remboursement.status_date', { status: justificatif.statut, date: moment(justificatif.dateStatut).format('DD/MM/YYYY') }) : justificatif.dateStatut}
        </p>
      );
    case JustificatifStatus.PENDING:
      return (
        <p className="waiting">
          {justificatif.statut}
        </p>
      );
    default:
      return null;
  }
}

export default function RemboursementJustificatif({
  justificatif, checkIsFileExist, setListFilesToSend, listFilesToSend, index,
}: Props) {
  const [showInfo, setShowInfo] = useState(false);
  const [data, setData] = useState <FileData>([]);
  const [docFileInputError, setDocFileInputError] = useState<boolean>(false);
  const infoRef = useRef(null);

  useEffect(() => {
    const listFilesToSendTmp = listFilesToSend;
    listFilesToSendTmp[index] = { data, justificatif };
    setListFilesToSend(listFilesToSendTmp);
    checkIsFileExist();
  }, [data]);

  useEffect(() => {
    if (!listFilesToSend[index]) {
      setData([]);
    }
  }, [listFilesToSend]);

  const closeInfo = () => {
    setShowInfo(false);
  };

  const handleInfo = () => {
    setShowInfo(!showInfo);
  };

  useOutsideCloser(infoRef, closeInfo);

  return (
    <div className="justificatif">
      {justificatif.libelle && (
        <div className="justificatif-header">
          <div className="title">{justificatif.libelle}</div>
          {justificatif.detail && (
            <div onClick={handleInfo} ref={infoRef}>
              <div className="icon-info fa fa-info-circle" />
            </div>
          )}
          {showInfo && <div className="detail">{justificatif.detail}</div>}
        </div>
      )}

      {docFileInputError && (
        <div className="justificatif-files">
          <div className="erreur">
            {I18n.t('document.error')}
          </div>
        </div>
      )}

      {data.files && data.files.length > 0 &&
        <div className="justificatif-files">
          {data.files.map((file: File) => (
            <div key={file.name}>
              {file.name}
            </div>
          ))}
        </div>
      }

      <div className="justificatif-body">
        <div className="title">
          <DispayStatus justificatif={justificatif} />
        </div>
        <FileInput setData={setData} data={data} setDocFileInputError={setDocFileInputError} code_doc={justificatif.libelle} />
      </div>
    </div>
  );
}