export const HOME = '/home';
export const DOCUMENTS = '/documents';
export const ASSISTANCE = '/assistance';
export const MENTIONS = '/mentions';
export const REVIEW = '/review';

export const baseApiUrl = '/api';

export const themes = {
  Mutuaide: {
	primary: '#00AB7F',
	secondary: '#FFF',
	tertiary: '#344E69',
	quaternary: '#ED821E',
	error: '#C0392B',
	background: '#F5F5F5',
	primaryFont: `"Roboto", Arial, serif`,
	secondaryFont: `"Roboto-Ligth", Arial, serif`,
  },
};

export const TYPE_FILE = {
  PANNE: 'Panne',
  ACCIDENT: 'Accident',
  OTHER: 'other',
};

export const TYPE_DOSSIER = {
  SANTE: 'Santé',
  AUTO: 'Auto',
  MEDICAL: 'Médical',
};

export const TYPE_WEBAPP = {
  SANTE: 'digilife',
  AUTO: 'digicar',
  MEDICAL: 'digimed',
};

// Type presation

export const AIDE_MENAGERE = {
  libelle: 'VOTRE AIDE MÉNAGÈRE',
  planning: 'Mon planning d\'aide ménagère',
  icon: 'picto-title logo--help'
};
export const AUXILIAIRE_VIE = {
  libelle: 'VOTRE AUXILIAIRE DE VIE',
  planning: 'Mon planning d\'auxiliaire de vie',
  icon: 'picto-title logo--help-house'
};
export const GARDE_ENFANT = {
  libelle: 'VOTRE GARDE D\'ENFANT',
  planning: 'Mon planning de garde d\'enfant',
  icon: 'picto-title logo--child'
};
export const TRANSPORT_ENFANT = {
  libelle: 'VOTRE TRANSPORT D\'ENFANT(S)',
  planning: 'Mon planning de transport d\'enfant',
  icon: 'picto-title logo--transport'
};
export const SOUTIEN_SCOLAIRE = {
  libelle: 'VOTRE SOUTIEN SCOLAIRE',
  planning: 'Mon planning de soutien scolaire',
  icon: 'picto-title logo--help-school'
};
export const LIVRAISON_REPAS = {
  libelle: 'VOTRE LIVRAISON DE REPAS',
  planning: 'Mon planning de livraison de repas',
  icon: 'picto-title logo--delivry'
};

export const TYPE_UNKNOWN = {
  libelle: 'Inconnu',
  planning: 'Mon planning',
  icon: ''
};

export const TYPE_PRESTATION = [
    AIDE_MENAGERE,
    AUXILIAIRE_VIE,
    GARDE_ENFANT,
    TRANSPORT_ENFANT,
    SOUTIEN_SCOLAIRE,
    LIVRAISON_REPAS
];

export const TAXI = {
  libelle: 'Taxi',
  icon: 'logo--taxi-noir',
};

export const AVION_LIGNE_ASSIS = {
  libelle: 'Avion de ligne assis',
  icon: 'logo--avion-noir',
};

export const AVION_SANITAIRE = {
  libelle: 'Avion sanitaire',
  icon: 'logo--avion-noir',
};

export const AVION_AIR_FRANCE = {
  libelle: 'Avion Air France',
  icon: 'logo--avion-noir',
};

export const AVION_EXTRA_SEAT = {
  libelle: 'Avion de ligne extra seat',
  icon: 'logo--avion-noir',
};

export const CONSULTATION = {
  libelle: 'Consultation',
  icon: 'logo--docteur-noir',
};

export const HOSPITALISATION = {
  libelle: 'Hospitalisation',
  icon: 'logo--docteur-noir',
};

export const TRAIN = {
  libelle: 'Train',
  icon: 'logo--train-noir',
};

export const HOTEL = {
  libelle: 'Hôtel',
  icon: 'logo--hotel-noir',
};

export const VEHICULE_LOCATION = {
  libelle: 'Véhicule de location',
  icon: 'logo--location-noir',
};

export const CARBURANT = {
  libelle: 'Carburant',
  icon: 'logo--carburant-noir',
};

export const TELECOMMUNICATIONS = {
  libelle: 'Télécommunications',
  icon: 'logo--telecommunication-noir',
};

export const FRAIS_MEDICAUX = {
  libelle: 'Remboursement des frais médicaux',
  icon: 'logo--remboursement-frais-medicaux',
};

export const FRAIS_MED = {
  libelle: 'Remboursement des Frais Méd',
  icon: 'logo--remboursement-frais-medicaux',
};

export const AMBULANCE = {
  libelle: 'Ambulance',
  icon: 'logo--ambulance-noir',
};

export const CHAUFFEUR = {
  libelle: 'Chauffeur',
  icon: 'logo--chauffeur-noir',
};

export const AVANCE_DE_FONDS = {
  libelle: 'Avance de fonds',
  icon: 'logo--dollars-noir',
};

export const HELICOPTERE = {
  libelle: 'Hélicoptère',
  icon: 'logo--helicopter-noir',
};

export const VSL = {
  libelle: 'VSL',
  icon: 'logo--ambulance-noir',
};

export const CAUTION_PENALE = {
  libelle: 'Caution pénale',
  icon: 'logo--prison-noir',
};

export const CERCUEIL = {
  libelle: 'Cercueil',
  icon: 'logo--cercueil-noir',
};

export const FRAIS_FUNERAIRES = {
  libelle: 'Frais Funéraires',
  icon: 'logo--cercueil-noir',
};

export const AVOCAT = {
  libelle: 'Honoraires avocat',
  icon: 'logo--avocat-noir',
};

export const PEAGE = {
  libelle: 'Péages autoroutes',
  icon: 'logo--peage-noir',
};

export const DENTISTE = {
  libelle: 'Urgence dentaire',
  icon: 'logo--dentiste-noir',
};

export const TRAINEAU = {
  libelle: 'Traîneau',
  icon: 'logo--traineau',
};

export const AVION_CIVIERE = {
  libelle: 'Avion de ligne civière',
  icon: 'logo--civiere-noir',
};

export const TRANSPORT_FUNERAIRE = {
  libelle: 'Transport funéraire',
  icon: 'logo--transport-funeraire',
};

export const MEDICAL_TYPE_PRESTATION = [
  TAXI,
  AVION_LIGNE_ASSIS,
  AVION_SANITAIRE,
  AVION_AIR_FRANCE,
  AVION_EXTRA_SEAT,
  AVION_CIVIERE,
  CONSULTATION,
  HOSPITALISATION,
  TRAIN,
  HOTEL,
  VEHICULE_LOCATION,
  CARBURANT,
  TELECOMMUNICATIONS,
  FRAIS_MED,
  FRAIS_MEDICAUX,
  AMBULANCE,
  CHAUFFEUR,
  AVANCE_DE_FONDS,
  HELICOPTERE,
  VSL,
  CAUTION_PENALE,
  CERCUEIL,
  FRAIS_FUNERAIRES,
  AVOCAT,
  PEAGE,
  DENTISTE,
  TRAINEAU,
  TRANSPORT_FUNERAIRE,
];
