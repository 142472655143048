// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import type { TaxiType } from '../types/SimutTypes';

type Props = {
  objet: TaxiType,
}
export default function Taxi({ objet }: Props) {
  return (
    <div className="bloc-type">
      <div className="bloc-title">{I18n.t('poursuite.taxi')}</div>
      <div className="bloc-field">
        <div className="values-solo">
          {objet.nom} : <span><a href={`tel:${objet.telephone}`}>{objet.telephone}</a></span>
        </div>
      </div>
      <div className="bloc-field">
        <div className="label">
          {I18n.t('poursuite.depart')}
        </div>
        <div className="values">
          {objet.adresse_depart}

        </div>
      </div>
      <div className="bloc-field">
        <div className="label">
          {I18n.t('poursuite.arrivee')}
        </div>
        <div className="values">
          {objet.adresse_arrivee}
        </div>
      </div>
    </div>
  );
}

