// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';

import JourRapatriement from './JourRapatriement';
import { Rapatriement } from '../../types/SimutTypes';

type Props = {
    rapatriements: Rapatriement[]
}

type State = {
    disableRight: boolean,
    disableLeft: boolean,
    dateSelected: string,
    scrolling: boolean,
}

export default class ListeRapatriementsContainer extends React.Component<Props, State> {
    state = {
        disableRight: false,
        disableLeft: false,
        dateSelected: '',
        scrolling: false,
    }

    _timeout = null;

    componentDidMount() {
        this._timeout = null;
        this.handleScroll = this.handleScroll.bind(this);

        const { rapatriements } = this.props;

        if (rapatriements.length > 0) {
            const today = moment(moment.now());

            const rapatriementToday: Rapatriement = rapatriements.find((rapatriement: Rapatriement) => {
                return today
                    .isSame(moment(rapatriement.dateRapatriement, "DD-MM-YYYY"), 'day')
            })

            if (rapatriementToday) {
                this.scrollToCorrectDay(rapatriementToday)
            }

            const rapatriementsSorted = this.sortRapatriements(rapatriements);

            if (today.isBefore(moment(rapatriementsSorted[0].dateRapatriement, "DD-MM-YYYY"))) {
                this.scrollToCorrectDay(rapatriementsSorted[0]);
            } else if (today.isAfter(moment(rapatriementsSorted[rapatriements.length - 1].dateRapatriement, "DD-MM-YYYY"))) {
                this.scrollToCorrectDay(rapatriementsSorted[rapatriements.length - 1]);
            } else {
                this.scrollToCorrectDay(rapatriementsSorted[0]);
            }

            const containerRapatriements = document.querySelector(`#container-rapatriements`);
            if (containerRapatriements) {
                containerRapatriements.addEventListener('scroll', this.handleScroll);
            }
        }
    }

    componentWillUnmount() {
        const containerRapatriements = document.querySelector(`#container-rapatriements`);
        if (containerRapatriements) {
            containerRapatriements.removeEventListener('scroll', this.handleScroll);
        }
    }

    handleScroll() {
        if (this._timeout){ //if there is already a timeout in process cancel it
            clearTimeout(this._timeout);
        }

        this._timeout = setTimeout(() => {
            this._timeout = null;
            this.setState({ scrolling: false });
            const containerRapatriements = document.querySelector(`#container-rapatriements`);
            if (containerRapatriements) {
                if (containerRapatriements.scrollLeft === 0) {
                    this.setState({ disableLeft: true })
                } else {
                    this.setState({ disableLeft: false })
                }
                if (containerRapatriements.scrollLeft >= containerRapatriements.scrollWidth - containerRapatriements.offsetWidth) {
                    this.setState({ disableRight: true })
                } else {
                    this.setState({ disableRight: false })
                }
            }
        },100);

        if (!this.state.scrolling) {
            this.setState({ scrolling: true });
        }
    }

    scrollToCorrectDay = (rapatriement: Rapatriement) => {
        const jourRapatriement = document.querySelector(`#rapatriement-${rapatriement.dateRapatriement.replaceAll('/', '-')}`);
        if (jourRapatriement) {
            this.setState({ dateSelected: rapatriement.dateRapatriement },
                () => {
                    jourRapatriement.scrollIntoView(true);
                    const container = document.querySelector(`#main-content`);
                    if (container) {
                        container.scrollTo({top: 0})
                    }
                });
        }
    }

    fireArrow = (isRight: boolean) => {
        if (this.props.rapatriements.length === 1 || (this.state.disableRight && this.state.disableLeft)) {
            return;
        }

        const rapatriementsSorted = this.sortRapatriements(this.props.rapatriements);
        const index = rapatriementsSorted.findIndex((rapatriement: Rapatriement) => rapatriement.dateRapatriement === this.state.dateSelected);

        if (isRight && !this.state.disableRight) {
            this.scrollToCorrectDay(rapatriementsSorted[index + 1]);
        } else if (!isRight && !this.state.disableLeft) {
            this.scrollToCorrectDay(rapatriementsSorted[index - 1]);
        }
    }

    sortRapatriements = (rapatriements: Rapatriement[]) => {
        return rapatriements.sort((rapatriementA: Rapatriement, rapatriementB: Rapatriement) => {
            const dateA = moment(rapatriementA.dateRapatriement, "DD-MM-YYYY");
            const dateB = moment(rapatriementB.dateRapatriement, "DD-MM-YYYY");
            if (dateA.isBefore(dateB)) {
                return -1;
            }
            if (dateA.isAfter(dateB)) {
                return 1;
            }
            return 0;
        })
    }

    setDateSelected = (date: string) => {
        this.setState({ dateSelected: date });
    }

    render() {
        const { rapatriements } = this.props;
        const { disableLeft, disableRight } = this.state;

        return (
            <>
                <div className="rapatriement-title">
                    {I18n.t('assistance.medicale.rapatriement.title')}
                    <i
                        onClick={() => this.fireArrow(true)}
                        className={`fas fa-chevron-right right-arrow${rapatriements.length === 1 || disableRight ? ' disabled' : ''}`}
                    />
                    <i onClick={() => this.fireArrow(false)}
                       className={`fas fa-chevron-left left-arrow${(rapatriements.length === 1 || disableLeft) ? ' disabled' : ''}`}
                    />
                </div>
                <div className={`container-rapatriements${rapatriements.length === 1 ? ' unique-jour-rapatriement' : ''}`} id="container-rapatriements">
                    {React.Children.toArray(
                        this.sortRapatriements(rapatriements).map((rapatriement: Rapatriement) => {
                            return (
                                <JourRapatriement
                                    rapatriement={rapatriement}
                                    id={`rapatriement-${rapatriement.dateRapatriement}`}
                                    setDateSelected={this.setDateSelected}
                                />
                            )
                        })
                    )}
                </div>
            </>
        );
    }
}
