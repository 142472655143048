import React, { Fragment } from 'react';
import { Field, Form } from 'react-final-form';
import { I18n } from 'react-redux-i18n';
import StarList from '../components/StarList';
import simutApi from '../network/api/simutApi';
import { queryParams } from '../utils';
import type { DossierType } from '../types/SimutTypes';
import { TYPE_DOSSIER } from '../const';

const stars = [
  { grade: 1, color: '#F58C22' },
  { grade: 2, color: '#F5AA22' },
  { grade: 3, color: '#F5C822' },
  { grade: 4, color: '#8fc21c' },
  { grade: 5, color: '#00d300' },
];

type Props = {
  dossier: DossierType,
};

type State = {
  response: string,
}

export default class Review extends React.Component<Props, State> {
  state = {
    response: '',
  };

  saveReview = (review) => {
    if (!review.consent) {
      review.commentaire = '';
    }
    simutApi
      .sendAvis(review)
      .then(rep => rep.json())
      .then((reponse) => {
        this.setState({ response: reponse });
      });
  };

  render() {
    const { dossier } = this.props;
    const initialValues = {
      token: queryParams.token,
      eval_accueil: 0,
      eval_prestation: 0,
      commentaire: '',
      consent: dossier.type_dossier === TYPE_DOSSIER.AUTO,
    };

    return (
      <div className="review">
        {!this.state.response &&
        <Fragment>
          <div className="title-review"> {I18n.t('review.title')} </div>
          <Form
            onSubmit={this.saveReview}
            initialValues={initialValues}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <div className="bloc-questions">
                  <div className="title"> {I18n.t('review.questions.contact')} </div>
                  <Field
                    component={StarList}
                    name="eval_accueil"
                    stars={stars}
                  />
                </div>
                <div className="bloc-questions">
                  <div className="title"> {I18n.t('review.questions.quality')} </div>
                  <Field
                    component={StarList}
                    name="eval_prestation"
                    stars={stars}
                  />
                </div>
                <div className="review-comment">
                  {
                    dossier.type_dossier !== TYPE_DOSSIER.AUTO && (
                        <>
                          <div className="wording-rgpd">
                            {I18n.t('review.rgpd.consent')}
                            <a href={`mailto:${I18n.t('review.rgpd.consentMail')}`}>{I18n.t('review.rgpd.consentMail')}</a>
                          </div>
                          <div className="consent-checkbox">
                            <Field
                                name="consent"
                                component="input"
                                type="checkbox"
                                id="yes"
                            />
                            <label htmlFor="yes">{I18n.t('review.rgpd.giveConsent')}</label>
                          </div>
                        </>
                    )
                  }
                  <div className="bloc-comment">
                    <div className="title"> {I18n.t('review.questions.comment')} </div>
                    <Field
                      name="commentaire"
                      component="textarea"
                      type="text"
                      className="comment-field"
                      placeholder={I18n.t('review.placeholder')}
                      disabled={!values.consent}
                      rows={5}
                      maxLength={1000}
                    />
                    <button type="submit">{I18n.t('review.submit')}</button>
                  </div>
                </div>
                <div className="wording-rgpd">
                  {I18n.t('review.rgpd.firstBlock')}
                  <a href="https://www.mutuaide.fr/donnees-personnelles/">{I18n.t('review.rgpd.link')}</a>
                  {I18n.t('review.rgpd.lastBlock')}
                </div>
              </form>
            )}
          />
        </Fragment>
        }
        {this.state.response && this.state.response.statusCode === '200' &&
          <div className="bloc-response">
            <div className="title"> {I18n.t('review.response')} </div>
            <i className="fas fa-thumbs-up fa-2x" />
          </div>
        }
      </div>
    );
  }
}
