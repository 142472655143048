// @flow
import * as React from 'react';

type Props = {
  isOpened: boolean,
  index: number,
  toggle: Function,
  children: React.Node
}

class CloseablePopIn extends React.PureComponent<Props> {
  ref: ?HTMLDivElement;

  componentDidMount() {
      document.addEventListener('click', (e) => {
        if (e.target.closest(".modal-component") == null && e.target.closest(".modal") != null) {
          this.props.toggle();
        }
      });
  }

  render() {
    return (
      <div
        className="closeable-component"
      >
        {this.props.children}
      </div>
    );
  }
}
export default CloseablePopIn;
