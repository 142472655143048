// @flow
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { PrestationRemboursement, DossierType, Justificatif } from '../../types/SimutTypes';
import RemboursementPrestation from './RemboursementPresation';

type Props = {
  justificatifsCommun: Justificatif[],
  prestations: PrestationRemboursement[],
  setRemboursement: () => void,
  dossier: DossierType,
}

export default function RemboursementPrestations({
  prestations,
  justificatifsCommun,
  dossier,
  setRemboursement,
}: Props) {
  const [customJustificatifsCommun, setCustomJustificatifsCommun] = useState<Justificatif[]>([]);

  if (prestations) {
    prestations.sort(sortPrestationByDate);
  }

  const morphJustificatifsCommunToPrestations = () => {
    const justificatifs = [];

    justificatifsCommun.forEach((justificatif: Justificatif) => {
      const justificatifCommun = {
        commun: true,
        libellePrestation: justificatif.libelle,
        dateDebutPrestation: '',
        dateFinPrestation: '',
        odmId: null,
        justificatifs: [{
          libelle: null,
          justificatifPrestationId: justificatif.justificatifPrestationId,
          statut: justificatif.statut,
          dateStatut: justificatif.dateStatut,
          detail: justificatif.detail,
          infos: justificatif.infos,
        }],
      };
      justificatifs.push(justificatifCommun);
    });
    setCustomJustificatifsCommun(justificatifs);
  };

  useEffect(() => {
    if (justificatifsCommun) {
      morphJustificatifsCommunToPrestations();
    }
  }, [justificatifsCommun]);

  function sortPrestationByDate(prestationA, prestationB) {
    const dateA = moment(prestationA.dateDebutPrestation);
    const dateB = moment(prestationB.dateDebutPrestation);

    if (!prestationA.dateDebutPrestation) {
      return -1;
    }
    if (!prestationB.dateDebutPrestation) {
      return 1;
    }

    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  }

  return (
    <div className="remboursement-prestations">
      {customJustificatifsCommun
        && customJustificatifsCommun.map((prestation: PrestationRemboursement) => (
          <RemboursementPrestation
            prestation={prestation}
            key={prestation.libellePrestation}
            dossier={dossier}
            setRemboursement={setRemboursement}
          />
      ))}
      {prestations && prestations.map((prestation: PrestationRemboursement) => (
        <RemboursementPrestation
          prestation={prestation}
          key={prestation.odmId}
          dossier={dossier}
          setRemboursement={setRemboursement}
        />
      ))}
    </div>
  );
}