// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';

export default function MentionsLegales() {
  return (
    <div className="home">
      <div className="mentions-bloc">
        <div className="main-title">  {I18n.t('mentions')} </div>
        <div className="title"> {I18n.t('mentionslegales.title.editeur')} </div>
        <div className="content"> {I18n.t('mentionslegales.text.editeur')} </div>
        <div className="title"> {I18n.t('mentionslegales.title.directeur')} </div>
        <div className="content"> {I18n.t('mentionslegales.text.directeur')} </div>
        <div className="title"> {I18n.t('mentionslegales.title.hebergeur')} </div>
        <div className="content"> {I18n.t('mentionslegales.text.hebergeur')} </div>
        <div className="title"> {I18n.t('mentionslegales.title.droit')} </div>
        <div className="content"> {I18n.t('mentionslegales.text.droit')} </div>
        <div className="title"> {I18n.t('mentionslegales.title.propriete')} </div>
        <div className="content"> {I18n.t('mentionslegales.text.propriete')} </div>
        <div className="title"> {I18n.t('mentionslegales.title.acces')} </div>
        <div className="content"> {I18n.t('mentionslegales.text.acces')} </div>
        <div className="title"> {I18n.t('mentionslegales.title.reclamations')} </div>
        <div className="content"> {I18n.t('mentionslegales.text.reclamations')} </div>
        <div className="title"> {I18n.t('mentionslegales.title.donnees')} </div>
        <div className="content"> {I18n.t('mentionslegales.text.donnees')} </div>
      </div>
    </div>

  );
}

