// @flow

import { I18n } from 'react-redux-i18n';
import React, { Fragment } from 'react';
import moment from 'moment';
import CarLocation from './CarLocation';

type Props = {
  objet: any,
  children: any,
}

export default function ServicePlus({ children, objet }: Props) {
  return (
    <div className="bloc-assist service-plus" >
      <div className="title-service-plus"> {I18n.t('servicePlus.title')}  </div>

      {objet &&
      objet.map(pour => (
        <Fragment>
          <div className="subtitle-service-plus"> {I18n.t('servicePlus.date', { date: moment(pour.date_depart).format('DD/MM/YYYY') })} </div>
          <CarLocation key={`${pour}:${pour.heure_depart}`} objet={pour} servicePlus />
        </Fragment>))}
      <div>
        {
          children
        }
      </div>
    </div>

  );
}
