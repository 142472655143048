// @flow
import React, { useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { PrestationRemboursement, DossierType, Justificatif, RemboursementDossier, JustificatifStatus } from '../../types/SimutTypes';
import RemboursementPrestations from '../../components/remboursement/RemboursementPrestations';


type Props = {
  remboursements: RemboursementDossier,
  dossier: DossierType,
}

export default function Remboursement({ dossier, remboursements }: Props) {
  const [justificatifEnAttenteCount, setJustificatifEnAttenteCount] = useState<number>(0);
  const [totalJustificatifCount, setTotalJustificatifCount] = useState<number>(0);
  const [remboursement, setRemboursement] = useState<RemboursementDossier>(remboursements);


  const getJustificatifCount = (remboursement: PrestationRemboursement[], statut?: string) => {
    let count = 0;
    if (!remboursement.remboursementPrestations) {
      return count;
    }

    // count justificatifs communs (by statut or not)
    remboursement.justificatifsCommun.forEach((justificatif: Justificatif) => {
      if (!statut) {
        count++;
      } else if (justificatif.statut === statut) {
        count++;
      }
    });

    // count justificatifs prestations (by statut or not)
    remboursement.remboursementPrestations.forEach((prestation: PrestationRemboursement) => {
      prestation.justificatifs.forEach((justificatif: Justificatif) => {
        if (!statut) {
          count++;
        } else if (justificatif.statut === statut) {
          count++;
        }
      });
    });
    return count;
  };

  useEffect(() => {
    setJustificatifEnAttenteCount(getJustificatifCount(remboursement, JustificatifStatus.PENDING));
    setTotalJustificatifCount(getJustificatifCount(remboursement));
  }, [remboursement]);

  if (remboursement && (remboursement.justificatifsCommun || remboursement.PrestationRemboursement)) {
    return (
      <div>
        <div className="remboursement-header">
          <div className="remboursement-summary">
            <p>{I18n.t('remboursement.avance_frais')}</p>
            {justificatifEnAttenteCount ?
              <p>
                {I18n.t('remboursement.justificatifs_en_attente', { attente: justificatifEnAttenteCount, total: totalJustificatifCount })}
              </p>
            : null}
          </div>
          <div className="rembousement-file-require">
            <p>
              {I18n.t('document.subtitle')}
            </p>
          </div>
          {remboursement && remboursement.wording && remboursement.wording.texte && (
            <div className="remboursement-helper">
              <p>{I18n.t('remboursement.tips')}</p>
              <div className="content">
                <div className="vertical-separator" />
                <div className="wording-text">
                  {
                    remboursement.wording.texte
                    && React.Children.toArray(remboursement.wording.texte.map((text: string) => (
                      <p>{text}</p>
                    )))
                  }
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          <RemboursementPrestations
            justificatifsCommun={remboursement.justificatifsCommun}
            prestations={remboursement.remboursementPrestations}
            dossier={dossier}
            setRemboursement={setRemboursement}
          />
        </div>
      </div>
    );
  }
  return null;
}