// @flow

import React, { useState, useEffect } from 'react';
import { I18n } from 'react-redux-i18n';
import simutApi from '../../network/api/simutApi';
import type { DossierType, Prestation, Justificatif } from '../../types/SimutTypes';
import RemboursementPrestation from './RemboursementPresation';

type Props = {
  token: string,
  simut: {
    dossier: DossierType,
    depannage: any,
    remboursement: any,
    otherPrestations: any,
    poursuites: any,
    servicePlus: any,
    prestations: Prestation[],
  },
};

export default function DocumentsMedical(props: Props) {
  const [justificatifs, setJustificatifs] = useState<Justificatif[]>([]);

  const fetchDocuments = () => {
    simutApi.fetchDocuments(props.token)
      .then(rep => rep.json())
      .then((documents) => {
        convertAndSetDocuments(documents);
      });
  };

  const convertAndSetDocuments = (documents) => {
    setJustificatifs(convertDocuments(documents));
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  const convertDocuments = (documents): Justificatif[] => documents.map((document: Document) => ({
    libelle: document.nom_doc,
    justificatifPrestationId: document.code_doc,
    statut: document.statut,
    dateStatut: document.date_maj,
    detail: document.libelle_doc,
    infos: document.detail_doc,
  }));

  if (!props.simut) {
    return false;
  }
  return (
    <div className="documents">
      <div className="file">
        <div className="documents-header">
          <div className="icon fa fa-id-card-alt" />
          <div className="info-line">{I18n.t('document.dossierNumber', { number: props.simut.dossier.numero_dossier })}</div>
          <div className={`info-line ${props.simut.dossier.statut_dossier === 'En cours' ? 'info-line--green' : ''}`}>
            <i className="fas fa-circle fa-1x" /> {props.simut.dossier.statut_dossier}
          </div>
        </div>
      </div>
      <div className="remboursement-prestations">
        <RemboursementPrestation prestation={{ justificatifs: justificatifs }} dossier={props.simut.dossier} token={props.token} convertAndSetDocuments={convertAndSetDocuments} />
      </div>
    </div>
  );
}

