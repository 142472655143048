// @flow

import React from 'react';

type Props = {
  onClick: Function,
  color: string,
}

const DEFAULT = '#BEBEBE';

export default function Star({ onClick, color }: Props) {
  return (
    <svg
      width="1.5rem"
      height="1.5rem"
      viewBox="0 0 51 51"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      onClick={onClick}
    >
      <g transform="matrix(1,0,0,1,-3307,-539)">
        <g transform="matrix(0.421973,0,0,1.22637,2912.85,73.7044)">
          <g id="star" transform="matrix(4.557,0,0,1.64418,936.146,378.794)">
            <path
              fill={color || DEFAULT}
              d="M26,10.109C26,10.39 25.797,10.656 25.594,10.859L19.922,16.39L21.266,24.202C21.282,24.311 21.282,24.405 21.282,24.515C21.282,24.921 21.095,25.296 20.641,25.296C20.422,25.296 20.203,25.218 20.016,25.109L13,21.422L5.984,25.109C5.781,25.218 5.578,25.296 5.359,25.296C4.906,25.296 4.703,24.921 4.703,24.515C4.703,24.406 4.719,24.312 4.734,24.202L6.078,16.39L0.39,10.859C0.203,10.656 -0.001,10.39 -0.001,10.109C-0.001,9.64 0.483,9.453 0.874,9.39L8.718,8.249L12.234,1.14C12.375,0.843 12.64,0.499 13,0.499C13.36,0.499 13.625,0.843 13.766,1.14L17.282,8.249L25.126,9.39C25.501,9.453 26.001,9.64 26.001,10.109L26,10.109Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
