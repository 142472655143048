// @flow
import React, { useState, useRef } from 'react';
import { Modal } from 'reactstrap';
import { I18n } from 'react-redux-i18n';
import type { DataFile } from '../../types/SimutTypes';
import { PrestationRemboursement, DossierType, Justificatif, Simut, JustificatifStatus, MailInfoJustificatif } from '../../types/SimutTypes';
import RemboursementJustificatif from './RemboursementJustificatif';
import DisplayStartEndDate from './DisplayStartEndDate';
import useOutsideCloser from '../../hooks/useOutsideCloser';
import IconType from './IconType';
import simutApi from '../../network/api/simutApi';
import { queryParams } from '../../utils';

type Props = {
  prestation: PrestationRemboursement,
  dossier: DossierType,
  setRemboursement: () => void,
}

export default function RemboursementPrestation({
  prestation, dossier, setRemboursement,
}: Props) {
  const [isFilesExist, setIsFilesExist] = useState<boolean>(false);
  const [filesToSend, setFilesToSend] = useState<DataFile[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [error, setError] = useState < boolean >(false);
  const [loading, setLoading] = useState < boolean >(false);
  const [showInfo, setShowInfo] = useState(false);

  const modalRef = useRef(null);
  const infoRef = useRef(null);

  const closeInfo = () => {
    setShowInfo(false);
  };

  const handleInfo = () => {
    setShowInfo(!showInfo);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useOutsideCloser(infoRef, closeInfo);

  useOutsideCloser(modalRef, handleCloseModal);

  const checkIsFileExist = () => {
    let isExist = false;

    filesToSend.forEach((element: DataFile) => {
      if (element.data.files && element.data.files.length > 0) {
        isExist = true;
      }
    });
    setIsFilesExist(isExist);
  };

  const handleSendMail = () => {
    setError(false);
    if (!dossier) {
      setError(true);
      setModalOpen(true);
      return;
    }
    const mailInfoList: MailInfoJustificatif[] = [];
    filesToSend.forEach((element: DataFile) => {
      if (element.data.files && element.data.files.length > 0) {
        const toSend = {
          token: queryParams.token,
          odmId: prestation.odmId,
          justificatif: {
            justificatifPrestationId: element.justificatif.justificatifPrestationId,
            libelle: element.justificatif.libelle,
            dateStatut: element.justificatif.dateStatut,
            detail: element.justificatif.detail,
            statut: JustificatifStatus.FORWARDED,
          },
        };

        let subject;
        if (element.justificatif.libelle) {
          subject = `${prestation.libellePrestation} - ${element.justificatif.libelle} - ${dossier.numero_dossier}`;
        } else {
          subject = `${prestation.libellePrestation} - ${dossier.numero_dossier}`;
        }

        const mailInfo = {
          subject,
          attachmentData: element.data.files,
          justificatifUpload: toSend,
        };
        mailInfoList.push(mailInfo);
      }
    });
    setLoading(true);
    simutApi.sendMailWitJustificatif(mailInfoList)
      .then((response: Response) => response.json())
      .then((simut: Simut) => {
        setRemboursement(simut.remboursements);
        setModalOpen(true);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setModalOpen(true);
        setLoading(false);
      });
    setFilesToSend([]);
  };

  return (
    <div className="remboursement-prestation">
      <Modal
        isOpen={modalOpen}
        className="modal-page remboursement-modal-page"
      >
        <div
          ref={modalRef}
          className="modal-component remboursement-modal-component"
        >
          <div className="remboursement-modal-content">
            <h2 className="remboursement-modal-title">
              {error ? I18n.t('remboursement.mailSentError') : I18n.t('remboursement.mailSent')}
            </h2>
            <button
              type="button"
              className="button close"
              onClick={handleCloseModal}
            >
              <i className="fa fa-check" />
            </button>
          </div>
        </div>
      </Modal>
      <div className={`header ${prestation.commun ? 'commun' : ''}`}>
        <div className="remboursement-prestation__header__title">
          <p>
            {prestation.libellePrestation}
          </p>
          <DisplayStartEndDate
            start={prestation.dateDebutPrestation}
            end={prestation.dateFinPrestation}
          />
        </div>
        {showInfo &&
          <div className="detail">
            {prestation.justificatifs[0].detail}
          </div>
        }
        {
          prestation.commun ? (prestation.justificatifs && prestation.justificatifs[0].detail &&
            <div onClick={handleInfo} ref={infoRef}>
              <div className="icon-info fa fa-info-circle" />
            </div>
          ) : (
            <div className="icon-container">
              <IconType libelle={prestation.libellePrestation} />
            </div>
          )
        }
      </div>
      <div className="body">
        <div className="justificatifs-container">
          {prestation && React.Children.toArray(prestation.justificatifs
            .map((justificatif: Justificatif, index: number) => (
              <RemboursementJustificatif
                justificatif={justificatif}
                checkIsFileExist={checkIsFileExist}
                dossier={dossier}
                setListFilesToSend={setFilesToSend}
                listFilesToSend={filesToSend}
                index={index}
              />
          )))}
        </div>
        {loading &&
          <div className="loading">
            <svg className="icon" xmlns="http://www.w3.org/2000/svg" style={{ margin: 'auto', background: '#fff', display: 'block' }} width="auto" height="auto" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
              <circle cx="50" cy="50" fill="none" stroke="#00ab7f" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138">
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1" />
              </circle>
            </svg>
          </div>
        }
        {isFilesExist &&
          <div className="send-container" onClick={handleSendMail}>
            <div className="send-button">
              <p>{I18n.t('document.transmit')}</p>
            </div>
          </div>
        }
      </div>
    </div>
  );
}
