// @flow
import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { FileData, Justificatif, DossierType } from '../../types/SimutTypes';
import { I18n } from 'react-redux-i18n';
import useOutsideCloser from '../../hooks/useOutsideCloser';
import FileInput from '../../components/remboursement/FileInput';

type Props = {
  justificatif: Justificatif,
  setIsFilesExist: (isFilesExist: boolean) => void,
  setListFilesToSend: (files: File[]) => void,
  listFilesToSend: File[],
  dossier: DossierType,
  index: number,
}

const DocumentStatus = {
  WAITING: 'En attente',
  TRANSMITTED_DATE: 'Transmis le',
  TRANSMITTED: 'Transmis',
  RECEIVED: 'Reçu',
  RECEIVED_DATE: 'Reçu le',
};

function DispayStatus({ justificatif }: Props) {
  switch (justificatif.statut) {
    case DocumentStatus.RECEIVED:
      return (
        <p className="success">
          {justificatif.statut}
        </p>
      );
    case DocumentStatus.RECEIVED_DATE:
      return (
        <p className="success">
          {justificatif.dateStatut ? I18n.t('remboursement.status_date', { status: justificatif.statut, date:  moment(justificatif.dateStatut).format('DD/MM/YYYY') }) : justificatif.dateStatut}
        </p>
      );
    case DocumentStatus.TRANSMITTED_DATE:
      return (
        <p className="recived">
          {justificatif.dateStatut ? I18n.t('remboursement.status_date', { status: 'Transmis', date:  moment(justificatif.dateStatut).format('DD/MM/YYYY') }) : justificatif.dateStatut}
        </p>
      );
    case DocumentStatus.TRANSMITTED:
      return (
        <p className="recived">
          {justificatif.statut}
        </p>
      );
    case DocumentStatus.WAITING:
      return (
        <p className="waiting">
          {justificatif.statut}
        </p>
      );
    default:
      return null;
  }
}

export default function RemboursementJustificatif({
  justificatif, setIsFilesExist, setListFilesToSend, listFilesToSend, index,
}: Props) {
  const [showInfo, setShowInfo] = useState(false);
  const [data, setData] = useState<FileData>([]);
  const [docFileInputError, setDocFileInputError] = useState<Boolean>(false);
  const infoRef = useRef(null);

  useEffect(() => {
    setIsFilesExist(data.files && data.files.length > 0);
    const listFilesToSendTmp = listFilesToSend;
    listFilesToSendTmp[index] = data;
    setListFilesToSend(listFilesToSendTmp);
  }, [data]);

  useEffect(() => {
    if (!listFilesToSend[index]) {
      setData([]);
    }
  }, [listFilesToSend]);

  const closeInfo = () => {
    setShowInfo(false);
  };

  const handleInfo = () => {
    setShowInfo(!showInfo);
  };

  useOutsideCloser(infoRef, closeInfo);

  return (
    <div className="justificatif">
      <div className="justificatif-header">
        <div className="title">
          {justificatif.libelle}<br /><small>{justificatif.infos}</small>
        </div>
        {justificatif.detail && (
          <div onClick={handleInfo} ref={infoRef}>
            <div className="icon-info fa fa-info-circle" />
          </div>
        )}
        {showInfo && <div className="detail">{justificatif.detail}</div>}
      </div>

      {docFileInputError && (
        <div className="justificatif-files">
          <div className="erreur">{I18n.t('document.error')}</div>
        </div>
      )}

      {data.files && data.files.length > 0 &&
        <div className="justificatif-files">
          {data.files.map((file: File) => (<div key={file.name}>{file.name}</div>))}
        </div>
      }

      <div className="justificatif-body">
        <div className="title">
          <DispayStatus justificatif={justificatif} />
        </div>
        <FileInput setData={setData} data={data} setDocFileInputError={setDocFileInputError} code_doc={justificatif.justificatifPrestationId} />
      </div>
    </div>
  );
}