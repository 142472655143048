// @flow
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { BeneficiaireRapatriement } from '../../types/SimutTypes';

type Props = {
    beneficiairesRapatriement: BeneficiaireRapatriement[],
}

export default class Beneficiaires extends React.Component<Props> {
    render() {
        const { beneficiairesRapatriement } = this.props;

        return (<>
            <div className="beneficiaires-rapatriement">
                {React.Children.toArray(beneficiairesRapatriement.map((beneficiaire: BeneficiaireRapatriement) => {
                    if (beneficiaire.numReservation) {
                        return (
                            <div className="beneficiaires-rapatriement-reservation">
                                <div>{beneficiaire.qualite} {beneficiaire.nom} {beneficiaire.prenom}</div>
                                <div className="numero-reservation">
                                    {I18n.t('assistance.medicale.rapatriement.prestationBooking', {
                                        number: beneficiaire.numReservation
                                    })}
                                </div>
                            </div>
                        )
                    }
                    return (<div>{beneficiaire.qualite} {beneficiaire.nom} {beneficiaire.prenom}</div>)
                }))}
                <div className="beneficiaires-label">
                    {I18n.t('assistance.medicale.rapatriement.beneficiaires')}
                </div>
            </div>

        </>)
    }
}
