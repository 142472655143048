// @flow
import React, { useRef } from 'react';
import { I18n } from 'react-redux-i18n';
import { DossierType, Prestation, FileData } from '../../types/SimutTypes';

const FILE_MAX_SIZE: number = 22000000; // La taille maximale d'un fichier est de 22Mo

const AUTHORIZED_FILES: string[] = [
  'image/bmp', // .bmp
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'image/gif', // .gif
  'image/jpeg', // .jpg ; .jpeg
  'image/png', // .png
  'application/pdf', // .pdf
];

type Props = {
  token: string,
  dossier: DossierType,
  simut: {
    depannage: any,
    remboursement: any,
    otherPrestations: any,
    poursuites: any,
    servicePlus: any,
    prestations: Prestation[],
    setDocFileInputError: (errors: string[]) => void,
  },
  setData: (files: File[]) => void,
  data: FileData[],
  code_doc: String,
};

export default function FileInput(props: Props) {
  const ref = useRef();

  const handleUploadFile = () => {
    props.setData([]);
    ref.current.click();
  };

  const handleAddFile = (files) => {
    const tempFiles = [];
    const filesArray: File[] = Array.from(files);
    const sizeTotal: number = filesArray.reduce(((accumulator, currentValue: File) => accumulator + currentValue.size), 0);

    if (filesArray.find((file: File) => !AUTHORIZED_FILES.includes(file.type)) !== undefined || sizeTotal > FILE_MAX_SIZE) {
      props.setDocFileInputError(true);
      props.setData([]);
      return;
    }

    props.setDocFileInputError(false);

    filesArray.forEach((file: File) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        tempFiles.push({ name: file.name, base64: reader.result, mimeType: file.type });
      };
      reader.onloadend = () => {
        props.setData({ code_doc: props.code_doc, files: tempFiles });
      };
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
    });

    ref.current.value = '';
  };

  return (
    <div
      className="button-add"
      value={document.nom_doc}
      onClick={handleUploadFile}
    >
      {props.data.files ? <div className="fa fa-edit" /> : <div className="fa fa-plus" />}
      <div>
        <p>{props.data.files ? I18n.t('document.edit') : I18n.t('document.add')}</p>
        <div>
          <input
            ref={ref}
            type="file"
            id="document"
            className="input-file"
            style={{ display: 'none' }}
            onChange={e => handleAddFile(e.target.files)}
            multiple
            accept={AUTHORIZED_FILES.join(',')}
          />
        </div>
      </div>
    </div>
  );
}