// @flow

import React from 'react';

type Props = {
  WrappedComponent: React.Component,
  provideTheme: Function,
};

export function withMabla({WrappedComponent, provideTheme}: Props) {
  return class extends React.Component<Props> {
    async componentDidMount() {
      const theme = await provideTheme;
      const patriarch = document.documentElement;
      theme && Object.entries(theme).forEach(entry => {
        patriarch.style.setProperty(`--${entry[0]}`, `${entry[1]}`);
      })
    }
    render() {
      return <WrappedComponent {...this.props} />
    }
  };
}
