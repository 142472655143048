// @flow

import React, { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import Advise from '../../components/Advise';
import BlocDepannage from '../../components/BlocDepannage';
import CarLocation from '../../components/CarLocation';
import Taxi from '../../components/Taxi';
import Hotel from '../../components/Hotel';
import Train from '../../components/Train';
import InterventionDepanneur from '../../components/InterventionDepanneur';
import ServicePlus from '../../components/ServicePlus';
import type { DossierType } from '../../types/SimutTypes';

type Props = {
  simut: {
    dossier: DossierType,
    depannage: any,
    otherPrestations: any,
    poursuites: any,
    servicePlus: any,
  },
}


export default class AssistanceAuto extends React.Component<Props> {
  handleAdvise = (poursuite: any) => {
    if (poursuite.type === 'hotel' && this.isAdviseHotel === true) {
      this.isAdviseHotel = false;
    } else if (poursuite.type === 'location' && this.isAdviseRent === true) {
      this.isAdviseRent = false;
    } else if (poursuite.type === 'train' && poursuite.retrait_borne && this.isAdviseTrainBillet === true) {
      this.isAdviseTrainBillet = false;
    } else if (poursuite.type === 'train' && !poursuite.retrait_borne && this.isAdviseTrainBorne === true) {
      this.isAdviseTrainBorne = false;
    }
  };
  resetAdvise = () => {
    this.isAdviseHotel = true;
    this.isAdviseRent = true;
    this.isAdviseTrainBorne = true;
    this.isAdviseTrainBillet = true;
  };

  isAdviseRent = true;
  isAdviseHotel = true;
  isAdviseTrainBorne = true;
  isAdviseTrainBillet = true;

  switchBloc = (poursuite: any) => ({
    taxi:
  <Taxi objet={poursuite} />,
    hotel:
  <Fragment>
    <Hotel objet={poursuite} />
    {this.isAdviseHotel && <Advise
      title={I18n.t('advise.title')}
      message={I18n.t('advise.hotel')}
    />}
  </Fragment>,
    location:
  <Fragment>
    <CarLocation objet={poursuite} />
    {this.isAdviseRent && <Advise
      title={I18n.t('advise.title')}
      message={I18n.t('advise.location')}
    />
    }
  </Fragment>,
    train:
  <Fragment>
    <Train objet={poursuite} />
    {((this.isAdviseTrainBillet && poursuite.retrait_borne) ||
      (this.isAdviseTrainBorne && !poursuite.retrait_borne)) &&
      <Advise
        title={I18n.t('advise.title')}
        message={poursuite.retrait_borne ? I18n.t('advise.train_ebillet') : I18n.t('advise.train_borne')}
      />
    }
  </Fragment>,
  }[poursuite.type]);

  render() {
    const { simut } = this.props;
    if (!simut) {
      return false;
    }

    return (
      <div className="home">
        {this.resetAdvise()}
        {simut && simut.depannage && simut.depannage.libelle &&
        <InterventionDepanneur objet={simut.depannage} dossier={simut.dossier} />
        }
        {simut.poursuites.poursuites.length !== 0 &&
        <div className="bloc-assist pursuit">
          <div className="title-pursuit"> {I18n.t('assistance.title')}</div>


          {simut.poursuites.poursuites.length !== 0 &&
          simut.poursuites.poursuites.map(pour => (
            <BlocDepannage
              key={`${pour}:${pour.heure_depart}:${pour.date_depart}`}
              type={pour.type}
              heure={pour.heure_depart}
              date={pour.date_depart}
            >
              {this.switchBloc(pour)}
              {this.handleAdvise(pour)}

            </BlocDepannage>))
          }
        </div>}
        {simut.servicePlus.poursuites.length !== 0 &&
        <ServicePlus
          objet={simut.servicePlus.poursuites}
        >
          {this.isAdviseRent && <Advise
            title={I18n.t('advise.title')}
            message={I18n.t('advise.location')}
          />}
        </ServicePlus>}
      </div>
    );
  }
}
