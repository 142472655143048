// @flow

import React from 'react';
import {I18n} from 'react-redux-i18n';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import type {DossierType} from '../../types/SimutTypes';
import {queryParams} from '../../utils';
import simutApi from '../../network/api/simutApi';
import Remboursement from '../../components/remboursement/Remboursement';

type Props = {
  remboursements: RemboursementDossier,
  dossier: DossierType,
  history?: Object,
}

type State = {
  geolocResponse: boolean
}

class DossierAuto extends React.PureComponent<Props, State> {

  state = {
	geolocResponse: 'NONE',
  };

  success = (pos, token) => {
	const {coords} = pos;
	simutApi.sendGeoloc({
	  token: token,
	  latitude: coords.latitude,
	  longitude: coords.longitude,
	})
		.then(() => this.setState({geolocResponse: 'SUCCESS'}))
		.catch(() => this.setState({geolocResponse: 'ERROR'}))
  };

  error = () => this.setState({geolocResponse: 'ERROR'});

  getGeoloc = (token) => {
	navigator.geolocation.getCurrentPosition((coords) => this.success(coords, token), this.error)
  };

  render() {
    const { remboursements } = this.props;
		const { dossier } = this.props;
		if (!dossier) {
	  	return false;
		}

	return (
		<div>
		  <div className="file">
			<div className="icon fa fa-user"/>
			<div className="info-line">{I18n.t('dossier.number', {number: dossier.number})}</div>
			<div className="info-line">{I18n.t('dossier.incident', {type: dossier.type})} <span
				className="info">{moment(dossier.date).format('DD/MM/YYYY')}</span> {I18n.t('dossier.address')} <span
				className="info">{dossier.address}</span></div>
		  </div>
		  <div className="file">
			<div className={`icon fas fa-map-marked ${this.state.geolocResponse === 'ERROR' ? 'icon--error' : ''}`}/>
			{
			  this.state.geolocResponse === 'NONE' &&
			  <button className="digi-button"
					  onClick={() => this.getGeoloc(queryParams.token)}>{I18n.t('dossier.locateMe')}</button>
			}
			{
			  this.state.geolocResponse === 'SUCCESS' &&
			  <>
				<div className="info-line">{I18n.t('geolocation.success')}</div>
			  </>
			}
			{
			  this.state.geolocResponse === 'ERROR' &&
			  <>
				<div className="info-line">{I18n.t('geolocation.error')}</div>
			  </>
			}
		  </div>
      	<Remboursement remboursements={remboursements} dossier={dossier} />
		</div>
	);
  }
}

export default withRouter(DossierAuto);

