// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import type { TrainType } from '../types/SimutTypes';

type Props = {
  objet: TrainType,
}

export default function Train({ objet }: Props) {
  return (
    <div className="bloc-type">
      <div className="bloc-title">{I18n.t('poursuite.train')}</div>
      <div className="bloc-field">
        <div className="label">
          {I18n.t('poursuite.depart')}
        </div>
        <div className="values">
          {objet.nom_depart} {I18n.t('poursuite.a')} {objet.heure_depart}
        </div>
      </div>
      <div className="bloc-field">
        <div className="label">
          {I18n.t('poursuite.arrivee')}
        </div>
        <div className="values">
          {objet.nom_arrivee} {I18n.t('poursuite.a')}  {objet.heure_arrivee}
        </div>
      </div>
      {
        objet.retrait_borne &&
        <div className="bloc-field">
          <div className="label">
            {I18n.t('poursuite.eTicket')}
          </div>
        </div>
      }
      {objet.code_reservation &&
      <div className="bloc-field">
        <div className="label">
          {I18n.t('poursuite.reservation')}
        </div>
        <div className="values">
          {objet.code_reservation}
        </div>
      </div>}
    </div>
  );
}
