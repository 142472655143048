
// @flow

import React, { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import GoogleMap from './GoogleMap';
import googleApi from '../network/api/googleApi';
import type { DepannageType, DossierType, GeoType } from '../types/SimutTypes';
import simutApi from '../network/api/simutApi';
import { queryParams } from '../utils';

type Props = {
  objet: DepannageType,
  dossier: DossierType
}

type State = {
  apiKey: '',
  geo: GeoType,
}

class InterventionDepanneur extends React.Component<Props, State> {
  state = {
    apiKey: '',
    geo: {
      latitude: 0,
      longitude: 0,
      error_message: '',
    },
  };

  componentDidMount() {
    googleApi
      .fetchKey()
      .then(rep => rep.text())
      .then((key) => {
        this.setState({ apiKey: key });
      });
    if (queryParams.token) {
      simutApi
        .fetchGeo(queryParams.token)
        .then(rep => rep.json())
        .then((json) => {
          this.setState({ geo: json });
        });
    }

    setInterval(() => {
      if (queryParams.token) {
        simutApi
          .fetchGeo(queryParams.token)
          .then(rep => rep.json())
          .then((json) => {
            this.setState({ geo: json });
          });
      }
    }, 60000);
  }

  componentWillUnmount() {
    clearInterval();
  }

  render() {
    const delai = this.props.objet.delai;
    parseInt(this.props.objet.delai, 10) < 60 ? I18n.t('depannage.minutes') : '';
    const depot = this.props.objet.depot && (this.props.objet.depot.nom ||
      this.props.objet.depot.adresse || this.props.objet.depot.telephone);
    return (
      <div className="intervention-depanneur-bloc">
        <div className="title-intervention">
          {I18n.t('depannage.title')}
        </div>
        <div className="intervention-depanneur">

          <div className="intervention">
            <div className="intervention-title">
              {I18n.t('depannage.intervention')}
            </div>
            <div className="intervention-content">
              {delai || I18n.t('depannage.noInfos')}
            </div>
          </div>

          <div className="intervention">
            <div className="intervention-title">
              {I18n.t('depannage.depanneur')}
            </div>
            <div className="intervention-content">
              <div className="label">
                {this.props.objet.depanneur.nom} : <span><a href={`tel:${this.props.objet.depanneur.telephone}`}>{` ${this.props.objet.depanneur.telephone}`}</a></span>
              </div>
              {this.props.objet.libelle && depot &&
              <Fragment>
                <div className="label">
                  {I18n.t('depannage.livraison')}
                </div>
                {this.props.objet.depot.nom && <div className="value"> {this.props.objet.depot.nom} </div>}
                {this.props.objet.depot.adresse && <div className="value"> {this.props.objet.depot.adresse} </div>}
                {this.props.objet.depot.telephone &&
                  <div className="value">
                    <span><a href={`tel:${this.props.objet.depot.telephone}`}>{` ${this.props.objet.depot.telephone}`}</a></span>
                  </div>
                }
              </Fragment>
              }
            </div>
          </div>
        </div>
        <div>
          {
            this.state.geo.latitude !== 0 &&
            this.state.geo.longitude !== 0 &&
            this.props.dossier.gps &&
            this.state.apiKey &&
              (
                <div className="map">
                  <GoogleMap
                    apiKey={this.state.apiKey}
                    dossier={this.props.dossier}
                    depanneur={this.state.geo}
                  />
                </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(InterventionDepanneur);
