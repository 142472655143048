// @flow
import React from 'react';
import { DossierType, RemboursementDossier } from '../../types/SimutTypes';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import Remboursement from '../../components/remboursement/Remboursement';


type Props = {
  remboursements: RemboursementDossier,
  dossier: DossierType,
  history?: Object,
}

type State = {}

class DossierMedical extends React.PureComponent<Props, State> {
  state = {};

  render() {
    const { dossier } = this.props;
    const { remboursements } = this.props;
    if (!dossier) {
      return false;
    }

    return (
      <div>
        <div className="file">
          <div className="icon fa fa-user" />
          <div className="info-line">
            <p>{dossier.nom_cdo}</p>
            <p>{I18n.t('dossier.reference', { reference: dossier.numero_dossier })}</p>
            {
              React.Children.toArray(dossier.beneficiaires.map(beneficiaire => (
                <p>
                  {I18n.t('dossier.beneficiary', { beneficiary: `${beneficiaire.civilite} ${beneficiaire.nom} ${beneficiaire.prenom}` })}
                </p>
                )))
            }
            <p>{I18n.t('dossier.location', { address: dossier.adresse })}</p>
          </div>
        </div>
        <div className="file file--assistance">
          <div className="info-line">
            <span className="info">
              {I18n.t('assistance.medicale.title')}
            </span>
          </div>
          <div className="separator" />
          <div className="info-line">
            <span className="info info--italic">
              <a
                href={`mailto:${dossier.email}?Subject=${dossier.numero_dossier} ${dossier.beneficiaires[0].nom} Envoyé depuis l'interface client`}
              >{dossier.email}
              </a>
              -
              <a href={`tel:${dossier.telephone}`}> {dossier.telephone}</a>
            </span>
          </div>
        </div>
        <div>
          <Remboursement remboursements={remboursements} dossier={dossier} />
        </div>
      </div>
    );
  }
}

export default withRouter(DossierMedical);
