// @flow

import React from 'react';
import moment from 'moment';
import {I18n} from "react-redux-i18n";
import { Modal } from 'reactstrap';
import type {DetailPlanning, TypePrestation} from "../types/SimutTypes";
import CloseablePopIn from "./modal/CloseablePopIn";
import PlanningIntervention from "./PlanningIntervention";
import PlanningTrajet from "./PlanningTrajet";

type Props = {
  togglePlanning: Function,
  isOpen: boolean,
  detailPlanning: DetailPlanning,
  type_prestation: TypePrestation,
  index: number,
}

export default function PlanningModal({ togglePlanning, isOpen, detailPlanning, type_prestation, index }: Props) {
  const comingTrajet = detailPlanning.trajet ?
      detailPlanning.trajet.filter(trajet => moment(trajet.trajet_aller.date, 'DD/MM/YYYY').diff(moment()) > 0) : [];
  const pastTrajet = detailPlanning.trajet ?
      detailPlanning.trajet.filter(trajet => moment(trajet.trajet_aller.date, 'DD/MM/YYYY').diff(moment()) < 0) : [];
  const comingIntervention = detailPlanning.plannings ?
      detailPlanning.plannings.filter(planning => moment(planning.date, 'DD/MM/YYYY').diff(moment()) > 0) : [];
  const pastIntervention = detailPlanning.plannings ?
      detailPlanning.plannings.filter(planning => moment(planning.date, 'DD/MM/YYYY').diff(moment()) < 0) : [];
  return (
    <CloseablePopIn isOpened={isOpen} toggle={togglePlanning} index={index}>
      <Modal
          isOpen={isOpen}
          className="modal-page"
      >
        <div id={'modal' + index} className="modal-component">
          <div className="modal-header">
            <div className="modal-title">
              {type_prestation.icon !== '' ? <i className={type_prestation.icon} /> : <i className="picto logo--OTHER"/>}
              <span>{type_prestation.planning}</span>
            </div>
            <div className="modal-description">
              {detailPlanning.info_contact && (detailPlanning.info_contact.cle + " : " + detailPlanning.info_contact.valeur)}
            </div>
          </div>

          <div className="modal-body">
            {
              comingIntervention.length > 0 &&
              <div className="planning-intervention">
                <div className="intervention-state">{I18n.t('planning.coming_intervention')}</div>
                {React.Children.toArray(comingIntervention.map(planning => {
                  return (
                      <PlanningIntervention planning={planning}/>
                  )
                }))}
              </div>
            }

            {
              pastIntervention.length > 0 &&
              <div className="planning-intervention">
                <div className="intervention-state">{I18n.t('planning.past_intervention')}</div>
                {pastIntervention.map(planning => {
                  return (
                      <PlanningIntervention planning={planning}/>
                  )
                })}
              </div>
            }

            {
              comingTrajet.length > 0 &&
              <div className="planning-intervention">
                <div className="intervention-state">{I18n.t('planning.coming_trip')}</div>
                {comingTrajet.map(trajet => {
                  return (
                      <PlanningTrajet trajet={trajet}/>
                  )
                })}
              </div>
            }

            {
              pastTrajet.length > 0 &&
              <div className="planning-intervention">
                <div className="intervention-state">{I18n.t('planning.past_trip')}</div>
                {pastTrajet.map(trajet => {
                  return (
                      <PlanningTrajet trajet={trajet}/>
                  )
                })}
              </div>
            }
          </div>
          <div className="modal-footer">
            <button onClick={() => togglePlanning()}>
              {I18n.t('actions.BACK')}
            </button>
          </div>

        </div>
      </Modal>
    </CloseablePopIn>
  );
}
