// @flow

import React from 'react';
import type { Adresse } from '../types/SimutTypes';

type Props = {
  heure_dep: string,
  adresse_dep: Adresse,
  adresse_ret: Adresse,
}

export default function BlocRoundTrip({heure_dep, adresse_dep, adresse_ret}: Props) {
  return (
      <div>
        <div className="bloc-round-trip">
          <div className="flux">
            <div className="date">
              {heure_dep}
            </div>
          </div>
          <div className="picto-type">
            <i className="picto fas fa-map-marker-alt" />
          </div>
          <div className="main-poursuite">
            <div>
              <div>{adresse_dep.rue}</div>
              <div>{`${adresse_dep.cp} ${adresse_dep.ville}`}</div>
            </div>
          </div>
        </div>

        {
          adresse_ret && adresse_ret.rue &&
          <div className="bloc-round-trip">
            <div className="flux">
            </div>
            <div className="picto-type">
              <i className="picto fas fa-flag-checkered" />
            </div>
            <div className="main-poursuite">
              <div>
                <div>{adresse_ret.rue}</div>
                <div>{`${adresse_ret.cp} ${adresse_ret.ville}`}</div>
              </div>
            </div>
          </div>
        }
      </div>
  );
}
