
import { RestClient } from '../network';

const simutApi = {
  fetch: token =>
    new RestClient(`/simut/${token}`, 'GET').execute(),
  fetchGeo: token =>
    new RestClient(`/simut/geolocalisation/${token}`, 'GET').execute(),
  sendAvis: avis =>
    new RestClient('/simut/avisClient', 'POST').jsonBody(avis).execute(),
  sendGeoloc: geolocClient =>
    new RestClient('/simut/geolocalisation/client', 'POST').jsonBody(geolocClient).execute(),
  fetchDocuments: token =>
    new RestClient(`/simut/documents/${token}`, 'GET').execute(),
  sendMailWithDocuments: attachmentData =>
    new RestClient('/simut/documents', 'POST').jsonBody(attachmentData).execute(),
  sendMailWitJustificatif: attachmentData =>
    new RestClient('/simut/prestation/justificatif', 'POST').jsonBody(attachmentData).execute(),
};

export default simutApi;
