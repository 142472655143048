// @flow

import React from 'react';

type Props = {
  title: string,
  message: string,
}

export default function Advise({ title, message }: Props) {
  return (
    <div className="advise">
      <div className="title">{title}</div>
      <div className="message">
        <p>{message}</p>
      </div>
    </div>
  );
}
