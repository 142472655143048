// @flow
import React from 'react';
import { AccompagnementMedical } from '../../types/SimutTypes';
import { I18n } from 'react-redux-i18n';

type Props = {
    accompagnementMedical: AccompagnementMedical,
}

export default class Accompagnement extends React.Component<Props> {
    render() {
        const { accompagnementMedical } = this.props;
        return (
            <div className="accompagnement-medical">
                <div className="picto-type">
                    <i className="picto logo--docteur-blanc" />
                </div>
                <div className="liste-accompagnants">
                    <div className="titre">{I18n.t('assistance.medicale.rapatriement.medicalAssistance')}</div>
                    <div>{accompagnementMedical.beneficiaire.qualite} {accompagnementMedical.beneficiaire.nom} {accompagnementMedical.beneficiaire.prenom}</div>
                </div>
            </div>
        );
    }
}
