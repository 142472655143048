// @flow
import React from 'react';
import { I18n } from 'react-redux-i18n';

import Beneficiaires from './Beneficiaires';
import type { PrestationRapatriement } from '../../types/SimutTypes';

type Props = {
    prestation: PrestationRapatriement
}

export default function Prestation({ prestation }: Props) {
  const getTrainNumber = () => (
    <div className="information-prestation">
      <span className="libelle-information">{I18n.t('assistance.medicale.rapatriement.prestationTrain')}</span>
      {prestation.numTrain}
    </div>
  );

  const getPlaneNumber = () => (
    <div className="information-prestation">
      <span className="libelle-information">{I18n.t('assistance.medicale.rapatriement.prestationPlane')}</span>
      {prestation.numVol}
    </div>
  );

  const getPlace = (labelKey: string, hourKey: string, key: string) => (
    <div className="information-prestation">
      <span className="libelle-information">{I18n.t(`assistance.medicale.rapatriement.${labelKey}`)}</span>
      {I18n.t('assistance.medicale.rapatriement.address', {
                adresse1: prestation[key].adresse.ligne1 || '',
                adresse2: prestation[key].adresse.ligne2 || '',
                adresse3: prestation[key].adresse.ligne3 || '',
                cp: prestation[key].adresse.cp || '',
                ville: prestation[key].adresse.ville || '',
            })}
      {prestation[key].heure &&
                I18n.t(`assistance.medicale.rapatriement.${hourKey}`, {
                    heure: prestation[key].heure,
                })}
    </div>
  );

  const getNightNumber = () => (
    <div className="information-prestation">
      <span className="libelle-information">{I18n.t('assistance.medicale.rapatriement.prestationHotel')}</span>
      {prestation.nombreNuit}
    </div>
  );

  const getEquipments = () => (
    <div className="information-prestation">
      <span
        className="libelle-information"
      >{I18n.t('assistance.medicale.rapatriement.prestationEquipment')}
      </span>
      <ul>{React.Children.toArray(prestation.equipements.map((equipement: string) => <li>{equipement}</li>))}</ul>
    </div>
  );

  const getPictoType = (): string => {
    switch (prestation.libellePrestation.toUpperCase().trim()) {
      case ('AMBULANCE'):
        return 'ambulance';
      case ('AVION SANITAIRE'):
        return 'avion';
      case ('AVION AIR FRANCE'):
        return 'avion';
      case ('AVION DE LIGNE ASSIS'):
        return 'avion';
      case ('AVION DE LIGNE'):
        return 'avion';
      case ('AVION DE LIGNE EXTRA-SEAT (SIÈGE SUPPLÉMENTAIRE)'):
        return 'avion';
      case ('AVION DE LIGNE CIVIÈRE (VOYAGE ALLONGÉ)'):
        return 'avion';
      case ('TAXI'):
        return 'taxi';
      case ('VSL'):
        return 'taxi';
      case ('CHAUFFEUR'):
        return 'chauffeur';
      case ('CHAUFFEUR DE REMPLACEMENT'):
        return 'chauffeur';
      case ('HÔTEL'):
        return 'hotel';
      case ('TRAIN'):
        return 'train';
      default:
        return 'docteur';
    }
  };

  const getHotelPrestation = () => (
    <>
      <div className="information-prestation">
        {prestation.adresse.libelle ? `${prestation.adresse.libelle} - ` : ''}
        {I18n.t('assistance.medicale.rapatriement.address', {
          adresse1: prestation.adresse.ligne1 || '',
          adresse2: prestation.adresse.ligne2 || '',
          adresse3: prestation.adresse.ligne3 || '',
          cp: prestation.adresse.cp || '',
          ville: prestation.adresse.ville || '',
        })}
      </div>
      <div className="information-prestation">
        <span>{prestation.dateDebut} - {prestation.dateFin}</span>
      </div>
      {(prestation.nombreNuit || prestation.nombreNuit === 0) && getNightNumber()}
    </>
  );


  return (
    <div className="prestation-rapatriement">
      <div className="picto-type">
        <i className={`picto logo--${getPictoType()}`} />
      </div>

      <div className="rapatriement-information">
        <div className="libelle-prestation">{prestation.libellePrestation}</div>
        {prestation.beneficiaires && prestation.beneficiaires.length > 0 &&
          <Beneficiaires beneficiairesRapatriement={prestation.beneficiaires} />
        }

        {prestation.libellePrestation.toUpperCase() === 'HÔTEL' ?
          getHotelPrestation() :
          (
            <>
              {prestation.numTrain && getTrainNumber()}

              {prestation.numVol && getPlaneNumber()}

              {prestation.lieuDepart && getPlace('prestationStartLabel', 'prestationHourStart', 'lieuDepart')}

              {prestation.lieuArrivee && getPlace('prestationEndLabel', 'prestationHourEnd', 'lieuArrivee')}

              {prestation.equipements.length > 0 && getEquipments()}

            </>
          )
        }
      </div>
    </div>
  );
}
