// @flow

import {TYPE_PRESTATION, TYPE_UNKNOWN} from "./const";

const parseQueryParams = () => {
  const validQueryParams = window.location.search.substring(1)
    .split('&')
    .filter(param => /^\w+[=][^&]+$/g.test(param))
    .join();
  return validQueryParams ?
    JSON.parse(`{"${
      decodeURI(validQueryParams)
        .replace(/"/g, '\\"')
        .replace(/,/g, '","')
        .replace(/=/g, '":"')}"}`) :
    {};
};
export const queryParams = parseQueryParams();

export function findTypePrestation(prestation: string) {
  let typePrestation = TYPE_UNKNOWN;
  TYPE_PRESTATION.forEach(prestationType => {
    if (prestationType.libelle === prestation) {
      typePrestation = prestationType;
    }
  });

  return typePrestation;
}
