// @flow
import React from 'react';

type Props = {
    libelle: string,
}

export default function ProchainContact ({ libelle }: Props) {
    return (
        <div className="prochain-contact">
            <i className="logo--telephone-cercle" />
            <div>
                {libelle}
            </div>
        </div>
    )
}
