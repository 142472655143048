// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import thunk from 'redux-thunk';
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'font-awesome-5/src/lib/css/all.min.css';

import App from './App';
import './sass/app.scss';
import fr from './i18n/locales/fr';
import en from './i18n/locales/en';
import reducers from './state/reducers';

const store = createStore(
  reducers,
  // eslint-disable-next-line no-underscore-dangle
  applyMiddleware(thunk),
);

syncTranslationWithStore(store);
store.dispatch(loadTranslations({
  fr,
  en,
}));
store.dispatch(setLocale('fr'));

/* eslint-disable react/jsx-filename-extension */
function wrapApp(RootComponent, reduxStore) {
  return (
    <Provider store={reduxStore}>
      <BrowserRouter basename="/">
        <Switch>
          <Route path="/" component={RootComponent} />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

// $FlowFixMe
ReactDOM.render(wrapApp(App, store), document.getElementById('root'));
