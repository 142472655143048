// @flow
import React, { useState, useRef } from 'react';
import { Modal } from 'reactstrap';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import { PrestationRemboursement, DossierType, Justificatif, JustificatifStatus, Document, MailInfoDossier } from '../../types/SimutTypes';
import RemboursementJustificatif from './RemboursementJustificatif';
import useOutsideCloser from '../../hooks/useOutsideCloser';
import simutApi from '../../network/api/simutApi';

type Props = {
  prestation: PrestationRemboursement,
  dossier: DossierType,
  token: string,
  convertAndSetDocuments: () => void,
}

export default function RemboursementPrestation({
  prestation, dossier, token, convertAndSetDocuments,
}: Props) {
  const [isFilesExist, setIsFilesExist] = useState<boolean>(false);
  const [filesToSend, setFilesToSend] = useState<[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const modalRef = useRef(null);

  useOutsideCloser(modalRef, () => {
    setModalOpen(false);
  });

  const handleSendMail = () => {
    setError(false);
    if (!dossier) {
      setError(true);
      setModalOpen(true);
      return;
    }
    const { nom } = dossier.beneficiaires[0];
    const numDossier = dossier.numero_dossier;
    const mailInfoList: MailInfoDossier[] = [];
    filesToSend.forEach((data) => {
      if (data.files && data.files.length > 0) {
        const documentToUpdate = {
          token,
          document: {
            code_doc: data.code_doc,
            date_maj: moment().format('YYYY-MM-DD').toString(),
            statut: JustificatifStatus.FORWARDED,
          },
        };
        const mailInfo = {
          subject: `${nom} ${numDossier}/${data.code_doc}`,
          attachmentData: data.files,
          documentToUpdate,
        };
        mailInfoList.push(mailInfo);
      }
    });
    simutApi.sendMailWithDocuments(mailInfoList)
      .then((response: Response) => response.json())
      .then((documents: Document[]) => {
        convertAndSetDocuments(documents);
        setModalOpen(true);
      })
      .catch(() => {
        setError(true);
        setModalOpen(true);
      });

    setFilesToSend([]);
  };

  return (
    <div className="remboursement-prestation">
      <Modal
        isOpen={modalOpen}
        className="modal-page remboursement-modal-page"
      >
        <div
          ref={modalRef}
          className="modal-component remboursement-modal-component"
        >
          <div className="remboursement-modal-content">
            <h2 className="remboursement-modal-title">
              {error ? I18n.t('remboursement.mailSentError') : I18n.t('remboursement.mailSent')}
            </h2>
            <button
              type="button"
              className="button close"
              onClick={() => setModalOpen(false)}
            >
              <i className="fa fa-check" />
            </button>
          </div>
        </div>
      </Modal>
      <div className="header">
        <div className="documents-header">
          <h2 className="documents-title">{I18n.t('document.title')}</h2>
          <small className="documents-subtitle">{I18n.t('document.subtitle')}</small>
        </div>
      </div>
      <div className="body">
        <div className="justificatifs-container">
          {prestation.justificatifs.map((justificatif: Justificatif, index) => (
            <RemboursementJustificatif
              justificatif={justificatif}
              setIsFilesExist={setIsFilesExist}
              key={index}
              dossier={dossier}
              setListFilesToSend={setFilesToSend}
              listFilesToSend={filesToSend}
              index={index}
            />
          ))}
        </div>
        {isFilesExist &&
          <div className="send-container" onClick={handleSendMail}>
            <div className="send-button">
              <p>{I18n.t('document.transmit')}</p>
            </div>
          </div>
        }
      </div>
    </div>
  );
}